import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, Tab, Tabs, Card, ListGroup, Modal } from 'react-bootstrap'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import ZoneForm from './ZoneForm.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone';
import { appConstants, localTimezone, facilitySectors } from 'utils/constants.js'
import { formatValidationErrors } from 'utils/format.js'
import { BiPlus } from 'react-icons/bi'
import { BiLinkExternal } from 'react-icons/bi'
import { BsFileEarmarkCheck } from "react-icons/bs";
import VisitResourceTable from '../tables/VisitResourceTable'
import ResourceForm from '../forms/ResourceForm.js'

function lastMonth() {
  const date = new Date()
  date.setDate(17)
  return new Date(date.setMonth(date.getMonth() - 1))
}

export default function SiteVisitForm(props) {
  const [id, setId] = useState(props.id || '')
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [actualStartDate, setActualStartDate] = useState(null)
  const [actualEndDate, setActualEndDate] = useState(null)
  const [dateSubmitted, setDateSubmitted] = useState(null)
  const [reportingDate, setReportingDate] = useState(lastMonth())
  const [userId, setUserId] = useState('')
  const [officeId, setOfficeId] = useState('')
  const [activityId, setActivityId] = useState('')
  const [governorateId, setGovernorateId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [territoryId, setTerritoryId] = useState('')
  const [zoneId, setZoneId] = useState('')
  const [sectorId, setSectorId] = useState('')
  const [virtual, setVirtual] = useState(false)
  const [visitType, setVisitType] = useState('')
  const [visitPurpose, setVisitPurpose] = useState('')
  const [repeatVisit, setRepeatVisit] = useState(false)
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [requestStatus, setRequestStatus] = useState('created')
  const [requestJustification, setRequestJustification] = useState('')
  const [requestJustificationDetails, setRequestJustificationDetails] = useState('')
  const [beneficiaries, setBeneficiaries] = useState('')
  const [subsectorIds, setSubsectorIds] = useState([])
  const [agreementIds, setAgreementIds] = useState([])
  const [ipPlanId, setIpPlanId] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [updated, setUpdated] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [showAddZoneModal, setShowAddZoneModal] = useState(false)
  const [createDuplicate, setCreateDuplicate] = useState(false)
  const [tpmLeader, setTpmLeader] = useState('')
  const [monitor1, setMonitor1] = useState('')
  const [monitor2, setMonitor2] = useState('')
  const [monitor3, setMonitor3] = useState('')
  const [monitor4, setMonitor4] = useState('')
  const [fulcrumAccount1Id, setFulcrumAccount1Id] = useState('')
  const [fulcrumAccount2Id, setFulcrumAccount2Id] = useState('')
  const [fulcrumAccount3Id, setFulcrumAccount3Id] = useState('')
  const [fulcrumAccount4Id, setFulcrumAccount4Id] = useState('')
  const [seaPoc, setSeaPoc] = useState('')
  const [visitStartTime, setVisitStartTime] = useState('')
  const [siteAddress, setSiteAddress] = useState('')
  const [beneficiaryDetails, setBeneficiaryDetails] = useState('')
  const [ipocDetails, setIpocDetails] = useState('')
  const [staffDetails, setStaffDetails] = useState('')
  const [observation, setObservation] = useState('')
  const [visitApprovalDetails, setVisitApprovalDetails] = useState('')
  const [securityRiskDetails, setSecurityRiskDetails] = useState('')
  const [logisticalDetails, setLogisticalDetails] = useState('')
  const [logisticalPoc, setLogisticalPoc] = useState('')
  const [vehicleDetails, setVehicleDetails] = useState('')
  const [visitApproved, setVisitApproved] = useState(false)
  const [dataReviewed, setDataReviewed] = useState(false)
  const [issueIdentified, setIssueIdentified] = useState(false)
  const [monitoringIssue, setMonitoringIssue] = useState('')
  const [otherIssues, setOtherIssues] = useState('')
  const [actionPoint, setActionPoint] = useState('')
  const [reviewComments, setReviewComments] = useState('')
  const [issueFixed, setIssueFixed] = useState(false)
  const [dateFixed, setDateFixed] = useState(null)
  const [tabKey, setTabKey] = useState(props.tabKey || 'sitevisit')
  const [fulcrumData, setFulcrumData] = useState({})
  const [monitoringCycleId, setMonitoringCycleId] = useState('')
  const [dataSyncDue, setDataSyncDue] = useState(null)
  const [reportDue, setReportDue] = useState(null)
  const [ipShareDue, setIpShareDue] = useState(null)
  const [sentToIp, setSentToIp] = useState(null)
  const [ipFeedbackDue, setIpFeedbackDue] = useState(null)
  const [ipFeedbackReceived, setIpFeedbackReceived] = useState(false)
  const [ipComments, setIpComments] = useState('')
  const [ipCommentsUpdated, setIpCommentsUpdated] = useState(null)
  const [ipCommentsUpdater, setIpCommentsUpdater] = useState('')
  const [reportWriterId, setReportWriterId] = useState('')
  const [resourcesCount, setResourcesCount] = useState(0)
  const [partner, setPartner] = useState('')
  const [targetedBeneficiaries, setTargetedBeneficiaries] = useState('')
  const [supportedStructures, setSupportedStructures] = useState('')
  const [typeOfDistributions, setTypeOfDistributions] = useState('')
  const [cashAmount, setCashAmount] = useState('')
  const [numberOfDistributions, setNumberOfDistributions] = useState('')
  const [beneficiariesTrained, setBeneficiariesTrained] = useState('')
  const [intermediariesTrained, setIntermediariesTrained] = useState('')
  const [servicesOffered, setServicesOffered] = useState('')
  const [otherInterventions, setOtherInterventions] = useState('')
  const [smlp2Comments, setSmlp2Comments] = useState('')
  const [ipSiteActive, setIpSiteActive] = useState(true)
  const [ipSiteInactiveReason, setIpSiteInactiveReason] = useState('')
  const [ipLiasonName, setIpLiasonName] = useState('')
  const [ipLiasonContact, setIpLiasonContact] = useState('')
  const [ipLiasonPhone, setIpLiasonPhone] = useState('')
  const [communityLeaderNameOne, setCommunityLeaderNameOne] = useState('')
  const [communityLeaderContactOne, setCommunityLeaderContactOne] = useState('')
  const [communityLeaderNameTwo, setCommunityLeaderNameTwo] = useState('')
  const [communityLeaderContactTwo, setCommunityLeaderContactTwo] = useState('')
  const [communityVolunteerNameOne, setCommunityVolunteerNameOne] = useState('')
  const [communityVolunteerContactOne, setCommunityVolunteerContactOne] = useState('')
  const [communityVolunteerNameTwo, setCommunityVolunteerNameTwo] = useState('')
  const [communityVolunteerContactTwo, setCommunityVolunteerContactTwo] = useState('')
  const [ipTabIpComments, setIpTabIpComments] = useState('')
  const [attempt, setAttempt] = useState('')
  const [reportTabSmlp2Comments, setReportTabSmlp2Comments] = useState('')
  const [reportTabSmlp2CommentsUpdated, setReportTabSmlp2CommentsUpdated] = useState(null)
  const [reportTabSmlp2CommentsUpdater, setReportTabSmlp2CommentsUpdater] = useState('')
  const [smlp2DataReviewed, setSmlp2DataReviewed] = useState(false)
  const [smlp2IssueIdentified, setSmlp2IssueIdentified] = useState(false)
  const [smlp2MonitoringIssue, setSmlp2MonitoringIssue] = useState('')
  const [smlp2OtherIssues, setSmlp2OtherIssues] = useState('')
  const [smlp2ActionPoint, setSmlp2ActionPoint] = useState('')
  const [smlp2ReviewComments, setSmlp2ReviewComments] = useState('')
  const [smlp2IssueFixed, setSmlp2IssueFixed] = useState(false)
  const [smlp2DateFixed, setSmlp2DateFixed] = useState(null)
  const [weight, setWeight] = useState(1)
  const [startFocused, setStartFocused] = useState(false)
  const [endFocused, setEndFocused] = useState(false)
  const [actualStartFocused, setActualStartFocused] = useState(false)
  const [actualEndFocused, setActualEndFocused] = useState(false)
  const [submittedFocused, setSubmittedFocused] = useState(false)
  const [fixedFocused, setFixedFocused] = useState(false)
  const [dataSyncDueFocused, setDataSyncDueFocused] = useState(false)
  const [reportDueFocused, setReportDueFocused] = useState(false)
  const [ipShareDueFocused, setIpShareDueFocused] = useState(false)
  const [sentToIpFocused, setSentToIpFocused] = useState(false)
  const [ipFeedbackDueFocused, setIpFeedbackDueFocused] = useState(false)
  const [smlp2FixedFocused, setSmlp2FixedFocused] = useState(false)
  const [bhaComments, setBhaComments] = useState('')
  const [bhaCommentsUpdated, setBhaCommentsUpdated] = useState(null)
  const [bhaCommentsUpdater, setBhaCommentsUpdater] = useState('')
  const [showAddResourceModal, setShowAddResourceModal] = useState(false)
  const [flashReportReady, setFlashReportReady] = useState(false)
  const [flashReportStatus, setFlashReportStatus] = useState('')
  const [ipFlashComments, setIpFlashComments] = useState('')
  const [ipFlashCommentsUpdated, setIpFlashCommentsUpdated] = useState(null)
  const [ipFlashCommentsUpdater, setIpFlashCommentsUpdater] = useState('')
  const [hasFlashReport, setHasFlashReport] = useState(false)
  const [showAddFlashReportModal, setShowAddFlashReportModal] = useState(false)
  const [bhaFlashComments, setBhaFlashComments] = useState('')
  const [bhaFlashCommentsUpdated, setBhaFlashCommentsUpdated] = useState(null)
  const [bhaFlashCommentsUpdater, setBhaFlashCommentsUpdater] = useState('')
  const [smlp2FlashComments, setSmlp2FlashComments] = useState('')
  const [smlp2FlashCommentsUpdated, setSmlp2FlashCommentsUpdated] = useState(null)
  const [smlp2FlashCommentsUpdater, setSmlp2FlashCommentsUpdater] = useState('')
  const [facilityType, setFacilityType] = useState('')
  const [hasFacilitySector, setHasFacilitySector] = useState(false)
  const [facilitySectorIds, setFacilitySectorIds] = useState([])

  useEffect(() => {
    if (tabKey === "fulcrum" && Object.keys(fulcrumData).length === 0) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.FORMS_FOR_VISIT_URL}/${props.id}`)
        setFulcrumData(response.data || {})
      }
      fetchData()
    }
  }, [tabKey])

  useEffect(() => {
    if (tabKey === "report" && !hasFlashReport) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}${props.id}.json`)
        setHasFlashReport(response.data.has_flash_report)
      }
      fetchData()
    }
  })

  useEffect(() => {
    if (updated) {
      props.refreshData()
      setUpdated(false)
    }
    if (deleted) {
      props.refreshData()
      setDeleted(false)
      props.closeModal()
    }
  }, [updated, deleted])

  useEffect(() => {
    if (createDuplicate && !id) {
      setCreateDuplicate(false)
      handleSubmit()
    }
  }, [createDuplicate])

  useEffect(() => {
    const fetchSectors = async () => {
      const response = await axios.get(`${appConstants.SECTORS_URL}`)
      const fac = response.data ? response.data.filter(s => facilitySectors.indexOf(s.name.trim()) != -1).map(s => s.id) : []
      setFacilitySectorIds(fac)
    }
    fetchSectors()
  }, [props.id])

  useEffect(() => {
    const hasFacilitySector = facilitySectorIds.indexOf(sectorId) != -1 ? true : false
    setHasFacilitySector(hasFacilitySector)
  }, [sectorId])

  useEffect(() => {
    if (props.id && !resourcesCount && !showAddResourceModal) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}/${props.id}.json`)
        setResourcesCount(response.data.get_resources_count)
        setDescription(response.data.data.description || '')
        setStartDate(response.data.start_date ? moment(response.data.start_date) : null)
        setEndDate(response.data.end_date ? moment(response.data.end_date) : null)
        setActualStartDate(response.data.actual_start_date ? moment(response.data.actual_start_date) : null)
        setActualEndDate(response.data.actual_end_date ? moment(response.data.actual_end_date) : null)
        setDateSubmitted(response.data.date_submitted ? moment(response.data.date_submitted) : null)
        setReportingDate(response.data.reporting_date ? new Date(response.data.reporting_date) : lastMonth())
        setUserId(response.data.user_id || '')
        setOfficeId(response.data.office_id || '')
        setActivityId(response.data.activity_id || '')
        setGovernorateId(response.data.governorate ? response.data.governorate.id : '')
        setDistrictId(response.data.district ? response.data.district.id : '')
        setTerritoryId(response.data.territory ? response.data.territory.id : '')
        setZoneId(response.data.zone_id || '')
        setSectorId(response.data.sector_id || '')
        setVirtual(response.data.virtual || false)
        setVisitType(response.data.visit_type || 'in_person')
        setVisitPurpose(response.data.visit_purpose || 'tpm')
        setRepeatVisit(response.data.repeat_visit || false)
        setLatitude(response.data.latitude || 0)
        setLongitude(response.data.longitude || 0)
        setRequestStatus(response.data.request_status || 'created')
        setRequestJustification(response.data.request_justification || '')
        setRequestJustificationDetails(response.data.data.request_justification_details || '')
        setBeneficiaries(response.data.data.beneficiaries || '')
        setSubsectorIds(response.data.subsectors ? response.data.subsectors.map(s => s.id) : [])
        setAgreementIds(response.data.agreements ? response.data.agreements.map(a => a.id) : [])
        setIpPlanId(response.data.ip_plan_id || '')
        setTpmLeader(response.data.tpm_leader || '')
        setMonitor1(response.data.monitor_1 || '')
        setMonitor2(response.data.monitor_2 || '')
        setMonitor3(response.data.monitor_3 || '')
        setMonitor4(response.data.monitor_4 || '')
        setFulcrumAccount1Id(response.data.fulcrum_account_1_id || '')
        setFulcrumAccount2Id(response.data.fulcrum_account_2_id || '')
        setFulcrumAccount3Id(response.data.fulcrum_account_3_id || '')
        setFulcrumAccount4Id(response.data.fulcrum_account_4_id || '')
        setSeaPoc(response.data.sea_poc || '')
        setVisitStartTime(response.data.visit_start_time || '')
        setSiteAddress(response.data.site_address || '')
        setBeneficiaryDetails(response.data.beneficiary_details || '')
        setIpocDetails(response.data.ipoc_details || '')
        setStaffDetails(response.data.staff_details || '')
        setObservation(response.data.observation || false)
        setVisitApprovalDetails(response.data.visit_approval_details || '')
        setSecurityRiskDetails(response.data.security_risk_details || '')
        setLogisticalDetails(response.data.logistical_details || '')
        setLogisticalPoc(response.data.logistical_poc || '')
        setVehicleDetails(response.data.vehicle_details || '')
        setVisitApproved(response.data.visit_approved || false)
        setDataReviewed(response.data.data_reviewed || false)
        setIssueIdentified(response.data.issue_identified || false)
        setMonitoringIssue(response.data.monitoring_issue || null)
        setOtherIssues(response.data.other_issues || '')
        setActionPoint(response.data.action_point || '')
        setReviewComments(response.data.review_comments || '')
        setIssueFixed(response.data.issue_fixed || false)
        setDateFixed(response.data.date_fixed ? moment(response.data.date_fixed) : null)
        setMonitoringCycleId(response.data.monitoring_cycle_id || '')
        setDataSyncDue(response.data.data_sync_due ? moment(response.data.data_sync_due) : null)
        setReportDue(response.data.report_due ? moment(response.data.report_due) : null)
        setIpShareDue(response.data.ip_share_due ? moment(response.data.ip_share_due) : null)
        setSentToIp(response.data.sent_to_ip ? moment(response.data.sent_to_ip) : null)
        setIpFeedbackDue(response.data.ip_feedback_due ? moment(response.data.ip_feedback_due) : null)
        setIpFeedbackReceived(response.data.ip_feedback_received || false)
        setIpComments(response.data.ip_comments || '')
        setIpCommentsUpdated(response.data.ip_comments_updated || null)
        setIpCommentsUpdater(response.data.get_ip_comments_updater || '')
        setReportWriterId(response.data.report_writer_id || '')
        setPartner(response.data.partner || '')
        setTargetedBeneficiaries(response.data.targeted_beneficiaries || '')
        setSupportedStructures(response.data.supported_structures || '')
        setTypeOfDistributions(response.data.type_of_distributions || '')
        setCashAmount(response.data.cash_amount || '')
        setNumberOfDistributions(response.data.number_of_distributions || '')
        setBeneficiariesTrained(response.data.beneficiaries_trained || '')
        setIntermediariesTrained(response.data.intermediaries_trained || '')
        setServicesOffered(response.data.services_offered || '')
        setOtherInterventions(response.data.other_interventions || '')
        setSmlp2Comments(response.data.smlp2_comments || '')
        setIpSiteActive(response.data.ip_site_active || false)
        setIpSiteInactiveReason(response.data.ip_site_inactive_reason || '')
        setIpLiasonName(response.data.ip_liason_name || '')
        setIpLiasonContact(response.data.ip_liason_contact || '')
        setIpLiasonPhone(response.data.ip_liason_phone || '')
        setCommunityLeaderNameOne(response.data.community_leader_name_1 || '')
        setCommunityLeaderContactOne(response.data.community_leader_contact_1 || '')
        setCommunityLeaderNameTwo(response.data.community_leader_name_2 || '')
        setCommunityLeaderContactTwo(response.data.community_leader_contact_2 || '')
        setCommunityVolunteerNameOne(response.data.community_volunteer_name_1 || '')
        setCommunityVolunteerContactOne(response.data.community_volunteer_contact_1 || '')
        setCommunityVolunteerNameTwo(response.data.community_volunteer_name_2 || '')
        setCommunityVolunteerContactTwo(response.data.community_volunteer_contact_2 || '')
        setIpTabIpComments(response.data.ip_tab_ip_comments || '')
        setAttempt(response.data.attempt || '')
        setReportTabSmlp2Comments(response.data.report_tab_smlp2_comments || '')
        setReportTabSmlp2CommentsUpdated(response.data.report_tab_smlp2_comments_updated || null)
        setReportTabSmlp2CommentsUpdater(response.data.get_report_tab_smlp2_comments_updater || '')
        setSmlp2DataReviewed(response.data.smlp2_data_reviewed || false)
        setSmlp2IssueIdentified(response.data.smlp2_issue_identified || false)
        setSmlp2MonitoringIssue(response.data.smlp2_monitoring_issue || null)
        setSmlp2OtherIssues(response.data.smlp2_other_issues || '')
        setSmlp2ActionPoint(response.data.smlp2_action_point || '')
        setSmlp2ReviewComments(response.data.smlp2_review_comments || '')
        setSmlp2IssueFixed(response.data.smlp2_issue_fixed || false)
        setSmlp2DateFixed(response.data.smlp2_date_fixed ? moment(response.data.smlp2_date_fixed) : null)
        setBhaComments(response.data.bha_comments || '')
        setBhaCommentsUpdated(response.data.bha_comments_updated || null)
        setBhaCommentsUpdater(response.data.get_bha_comments_updater || '')
        setFlashReportReady(response.data.flash_report_ready || false)
        setFlashReportStatus(response.data.flash_report_status || null)
        setIpFlashComments(response.data.ip_flash_comments || '')
        setIpFlashCommentsUpdated(response.data.ip_flash_comments_updated || null)
        setIpFlashCommentsUpdater(response.data.get_ip_flash_comments_updater || '')
        setHasFlashReport(response.data.has_flash_report || false)
        setBhaFlashComments(response.data.bha_flash_comments || '')
        setBhaFlashCommentsUpdated(response.data.bha_flash_comments_updated || null)
        setBhaFlashCommentsUpdater(response.data.get_bha_flash_comments_updater || '')
        setSmlp2FlashComments(response.data.smlp2_flash_comments || '')
        setSmlp2FlashCommentsUpdated(response.data.smlp2_flash_comments_updated || null)
        setSmlp2FlashCommentsUpdater(response.data.get_smlp2_flash_comments_updater || '')
        setWeight(response.data.weight || '')
        setFacilityType(response.data.facility_type || '')
        setHasFacilitySector(response.data.has_facility_sector || false)
      }

      fetchData()
    }
  }, [props.id, showAddResourceModal])


  const createFormData = function()  {
    let formData = new FormData()
    formData.append('monitoring_request[description]',  description)
    formData.append('monitoring_request[start_date]', startDate) 
    formData.append('monitoring_request[end_date]', endDate)
    formData.append('monitoring_request[actual_start_date]', actualStartDate)
    formData.append('monitoring_request[actual_end_date]', actualEndDate)
    formData.append('monitoring_request[date_submitted]', dateSubmitted)
    formData.append('monitoring_request[beneficiaries]', beneficiaries)
    formData.append('monitoring_request[request_justification]', requestJustification)
    formData.append('monitoring_request[request_justification_details]', requestJustificationDetails)
    formData.append('monitoring_request[virtual]', virtual || false)
    formData.append('monitoring_request[visit_type]', visitType || "")
    formData.append('monitoring_request[visit_purpose]', visitPurpose || "")
    formData.append('monitoring_request[repeat_visit]', repeatVisit || false)
    formData.append('monitoring_request[latitude]', latitude || 0)
    formData.append('monitoring_request[longitude]', longitude || 0)
    formData.append('monitoring_request[request_status]', requestStatus)
    formData.append('monitoring_request[user_id]', userId || "")
    formData.append('monitoring_request[office_id]', officeId || "")
    formData.append('monitoring_request[activity_id]', activityId || "")
    formData.append('monitoring_request[district_id]', districtId || "")
    formData.append('monitoring_request[territory_id]', territoryId || "")
    formData.append('monitoring_request[zone_id]', zoneId || "")
    formData.append('monitoring_request[sector_id]', sectorId || "")
    formData.append('monitoring_request[ip_plan_id]', ipPlanId || "")
    formData.append('monitoring_request[reporting_date]', reportingDate || "")
    formData.append('monitoring_request[tpm_leader]', tpmLeader || "")
    formData.append('monitoring_request[monitor_1]', monitor1 || "")
    formData.append('monitoring_request[monitor_2]', monitor2 || "")
    formData.append('monitoring_request[monitor_3]', monitor3 || "")
    formData.append('monitoring_request[monitor_4]', monitor4 || "")
    formData.append('monitoring_request[fulcrum_account_1_id]', fulcrumAccount1Id || "")
    formData.append('monitoring_request[fulcrum_account_2_id]', fulcrumAccount2Id || "")
    formData.append('monitoring_request[fulcrum_account_3_id]', fulcrumAccount3Id || "")
    formData.append('monitoring_request[fulcrum_account_4_id]', fulcrumAccount4Id || "")
    formData.append('monitoring_request[sea_poc]', seaPoc || "")
    formData.append('monitoring_request[visit_start_time]', visitStartTime || "")
    formData.append('monitoring_request[site_address]', siteAddress || "")
    formData.append('monitoring_request[beneficiary_details]', beneficiaryDetails || "")
    formData.append('monitoring_request[ipoc_details]', ipocDetails || "")
    formData.append('monitoring_request[staff_details]', staffDetails || "")
    formData.append('monitoring_request[observation]', observation || false)
    formData.append('monitoring_request[visit_approval_details]', visitApprovalDetails || "")
    formData.append('monitoring_request[security_risk_details]', securityRiskDetails || "")
    formData.append('monitoring_request[logistical_details]', logisticalDetails || "")
    formData.append('monitoring_request[logistical_poc]', logisticalPoc || "")
    formData.append('monitoring_request[vehicle_details]', vehicleDetails || "")
    formData.append('monitoring_request[visit_approved]', visitApproved || false)
    formData.append('monitoring_request[data_reviewed]', dataReviewed || false)
    formData.append('monitoring_request[issue_identified]', issueIdentified || false)
    monitoringIssue ? formData.append('monitoring_request[monitoring_issue]', monitoringIssue) : formData.append('monitoring_request[monitoring_issue]', '')
    formData.append('monitoring_request[other_issues]', otherIssues || "")
    formData.append('monitoring_request[action_point]', actionPoint || "")
    formData.append('monitoring_request[review_comments]', reviewComments || "")
    formData.append('monitoring_request[issue_fixed]', issueFixed || false)
    formData.append('monitoring_request[date_fixed]', dateFixed)
    formData.append('monitoring_request[monitoring_cycle_id]', monitoringCycleId || "")
    formData.append('monitoring_request[data_sync_due]', dataSyncDue || "")
    formData.append('monitoring_request[report_due]', reportDue || "")
    formData.append('monitoring_request[ip_share_due]', ipShareDue || "")
    formData.append('monitoring_request[sent_to_ip]', sentToIp || "")
    formData.append('monitoring_request[ip_feedback_due]', ipFeedbackDue || "")
    formData.append('monitoring_request[ip_feedback_received]', ipFeedbackReceived || false)
    formData.append('monitoring_request[ip_comments]', ipComments || "")
    formData.append('monitoring_request[report_writer_id]', reportWriterId || "")
    formData.append('monitoring_request[partner]', partner || "")
    formData.append('monitoring_request[targeted_beneficiaries]', targetedBeneficiaries || "")
    formData.append('monitoring_request[supported_structures]', supportedStructures || "")
    formData.append('monitoring_request[type_of_distributions]', typeOfDistributions || "")
    formData.append('monitoring_request[cash_amount]', cashAmount || "")
    formData.append('monitoring_request[number_of_distributions]', numberOfDistributions || "")
    formData.append('monitoring_request[beneficiaries_trained]', beneficiariesTrained || "")
    formData.append('monitoring_request[intermediaries_trained]', intermediariesTrained || "")
    formData.append('monitoring_request[services_offered]', servicesOffered || "")
    formData.append('monitoring_request[other_interventions]', otherInterventions || "")
    formData.append('monitoring_request[smlp2_comments]', smlp2Comments || "")
    formData.append('monitoring_request[ip_site_active]', ipSiteActive || false)
    formData.append('monitoring_request[ip_site_inactive_reason]', ipSiteInactiveReason || "")
    formData.append('monitoring_request[ip_liason_name]', ipLiasonName || "")
    formData.append('monitoring_request[ip_liason_contact]', ipLiasonContact || "")
    formData.append('monitoring_request[ip_liason_phone]', ipLiasonPhone || "")
    formData.append('monitoring_request[community_leader_name_1]', communityLeaderNameOne || "")
    formData.append('monitoring_request[community_leader_contact_1]', communityLeaderContactOne || "")
    formData.append('monitoring_request[community_leader_name_2]', communityLeaderNameTwo || "")
    formData.append('monitoring_request[community_leader_contact_2]', communityLeaderContactTwo || "")
    formData.append('monitoring_request[community_volunteer_name_1]', communityVolunteerNameOne || "")
    formData.append('monitoring_request[community_volunteer_contact_1]', communityVolunteerContactOne || "")
    formData.append('monitoring_request[community_volunteer_name_2]', communityVolunteerNameTwo || "")
    formData.append('monitoring_request[community_volunteer_contact_2]', communityVolunteerContactTwo || "")
    formData.append('monitoring_request[ip_tab_ip_comments]', ipTabIpComments || "")
    formData.append('monitoring_request[attempt]', attempt || "")
    formData.append('monitoring_request[report_tab_smlp2_comments]', reportTabSmlp2Comments || "")
    formData.append('monitoring_request[smlp2_data_reviewed]', smlp2DataReviewed || false)
    formData.append('monitoring_request[smlp2_issue_identified]', smlp2IssueIdentified || false)
    if (smlp2MonitoringIssue) {formData.append('monitoring_request[smlp2_monitoring_issue]', smlp2MonitoringIssue)}
    formData.append('monitoring_request[smlp2_other_issues]', smlp2OtherIssues || "")
    formData.append('monitoring_request[smlp2_action_point]', smlp2ActionPoint || "")
    formData.append('monitoring_request[smlp2_review_comments]', smlp2ReviewComments || "")
    formData.append('monitoring_request[smlp2_issue_fixed]', smlp2IssueFixed || false)
    formData.append('monitoring_request[smlp2_date_fixed]', smlp2DateFixed || "")
    formData.append('monitoring_request[bha_comments]', bhaComments || "")
    formData.append('monitoring_request[flash_report_ready]', flashReportReady || false)
    if (flashReportStatus) {formData.append('monitoring_request[flash_report_status]', flashReportStatus)}
    formData.append('monitoring_request[ip_flash_comments]', ipFlashComments || "")
    formData.append('monitoring_request[weight]', weight || "")
    formData.append('monitoring_request[facility_type]', facilityType || "")

    subsectorIds.forEach(s => {
      formData.append('monitoring_request[subsector_ids][]', s)
    })
    agreementIds.forEach(a => {
      formData.append('monitoring_request[agreement_ids][]', a)
    })

    return formData
  }

  const handleSubmit = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()
    let notice = id ? I18n.t("notices.update_success") : I18n.t("notices.create_success")
    if (id) {
      await axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, data )
      .then(res => {
        setIpCommentsUpdated(res.data.ip_comments_updated)
        setIpCommentsUpdater(res.data.get_ip_comments_updater)
        setIpFlashCommentsUpdated(res.data.ip_flash_comments_updated)
        setReportTabSmlp2CommentsUpdated(res.data.report_tab_smlp2_comments_updated)
        setReportTabSmlp2CommentsUpdater(res.data.get_report_tab_smlp2_comments_updater)
        setBhaCommentsUpdated(res.data.bha_comments_updated)
        setBhaCommentsUpdater(res.data.get_bha_comments_updater)
      })
      .catch(err => {
        notice = formatValidationErrors('monitoring_request', err.response.data)
      })
    } else {
      await axios.post(appConstants.MONITORING_EVENT_URL, data )
      .then(res => {
        setIpCommentsUpdated(res.data.ip_comments_updated)
        setIpCommentsUpdater(res.data.get_ip_comments_updater)
        setIpFlashCommentsUpdated(res.data.ip_flash_comments_updated)
        setReportTabSmlp2CommentsUpdated(res.data.report_tab_smlp2_comments_updated)
        setReportTabSmlp2CommentsUpdater(res.data.get_report_tab_smlp2_comments_updater)
        setBhaCommentsUpdated(res.data.bha_comments_updated)
        setBhaCommentsUpdater(res.data.get_bha_comments_updater)
      })
      .catch(err => {
        notice = formatValidationErrors('monitoring_request', err.response.data)
      })
    }
    setAlertMessage(notice)
    setUpdated(true)
  }

  const handleDelete = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.delete(`${appConstants.MONITORING_EVENT_URL}/${id}.json`)
    const notice = res.status === 204 ? I18n.t("notices.delete_success") : I18n.t("notices.delete_failure")
    setAlertMessage(notice)
    setDeleted(true)
  }

  const handleReportingDateChange = (e) => {
    let d = reportingDate || new Date()
    d.setDate(17)

    if (e.field=="year") {
      d.setYear(e.value)
    } else {
      d.setMonth(e.value, 17)
    }

    setReportingDate(d)
  }

  const renderAlertMessage = () => {
    return alertMessage ? (
      <Alert variant={'info'} onClose={() => setAlertMessage("")} dismissible>
        {alertMessage}
      </Alert>
    ) : ""
  }

  const renderAddZoneButton = () => {
    return territoryId && props.allowed.edit_site_visit ? (
      <Button variant="outline-secondary" onClick={() => setShowAddZoneModal(true)} className="add-collection-btn" title={I18n.t('zone.add')}><BiPlus /></Button>
    ) : ""
  }

  const closeAddZoneModal = (z) => {
    setShowAddZoneModal(false)
    setZoneId(z)
    setAlertMessage("")
  }

  const renderDuplicateButton = () => {
    if (id && props.allowed.create_site_visit) {
      return (<Button disabled={!props.allowed.create_site_visit} variant="outline-primary" onClick={() => {setId(""); setCreateDuplicate(true)}}>{I18n.t("navigation.duplicate")}</Button> )
    }
  }

  const renderDeleteButton = () => {
    if (id && props.allowed.create_site_visit) {
      return (<Button disabled={!props.allowed.create_site_visit} variant="outline-danger" style={{float: 'right'}} onClick={() => handleDelete()}>{I18n.t("navigation.delete")}</Button>)
    }
  }

  const renderCancelButton = () => {
    return (<Button variant="outline-secondary" style={{float: 'right'}} onClick={() => props.closeModal()}>{I18n.t("navigation.cancel")}</Button>)
  }

  const renderFlashReportUploaded = () => {
    if (hasFlashReport) {
      return (<span className="float-start darkorange mt-4 h4"><BsFileEarmarkCheck /></span>)
    }
  }

  const renderFulcrumTab = () => {
    return props.allowed.fulcrum_tab ? (
      <Tab eventKey="fulcrum" title={I18n.t('navigation.fulcrum_tab')} className="mb-3">
          <Row className="mt-3">
            { Object.keys(fulcrumData).map(k =>
                <Card key={k} className="mt-3">
                  <Card.Body>
                    <Card.Title>{fulcrumData[k].name} - {fulcrumData[k].records.length} <a href={appConstants.FULCRUM_URL + "apps/" + k} target="_blank" rel="noopener noreferrer"><BiLinkExternal /></a></Card.Title>
                    <ListGroup variant="flush">
                      { fulcrumData[k].records.map(r =>
                          <ListGroup.Item key={r.id}><a href={appConstants.FULCRUM_URL + "records/" + r.id} target="_blank" rel="noopener noreferrer">{ r.title ? r.title : r.id } - { r.status }</a></ListGroup.Item>
                        )
                      }
                    </ListGroup>
                  </Card.Body>
                </Card>
              )
            }
          </Row>
        </Tab>
    ) : ""
  }

  return showAddZoneModal ? (
    <ZoneForm
      authenticityToken={props.authenticityToken}
      zone={{territory_id: territoryId}}
      isModal={true}
      closeModal={closeAddZoneModal} />
  ) : (
    <Form>
      <Modal
        show={showAddResourceModal}
        onHide={()=>setShowAddResourceModal(false)}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('resource.add_a_resource')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResourceForm
            authenticityToken={props.authenticityToken}
            isModal={true}
            closeModal={()=>setShowAddResourceModal(false)}
            resource={{monitoring_request_id: parseInt(id), activities: [{id: activityId}], ip_plans: [{id: ipPlanId}], office_id: officeId, monitoring_cycle_id: monitoringCycleId, tags: [], collections: []}}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddFlashReportModal}
        onHide={()=>setShowAddFlashReportModal(false)}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('resource.add_a_flash_report')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResourceForm
            authenticityToken={props.authenticityToken}
            isModal={true}
            closeModal={()=>setShowAddFlashReportModal(false)}
            resource={{monitoring_request_id: parseInt(id), activities: [{id: activityId}], office_id: officeId, monitoring_cycle_id: monitoringCycleId, resource_type_id: props.flashReportResourceTypeId, tags: [], collections: []}}
          />
        </Modal.Body>
      </Modal>
      <Row>
        <Col>
          {renderAlertMessage()}
        </Col>
      </Row>
      <Tabs activeKey={tabKey} id="sitevisit-tabs" onSelect={(k) => setTabKey(k)}>
        <Tab eventKey="sitevisit" title={I18n.t('navigation.site_visit_tab')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={4} controlid="requestForm.activity">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.activity')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={activityId}
                onValueChange={(e) => setActivityId(e ? e.value : "")}
                url={appConstants.ACTIVITIES_URL + ".json"}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.visit_weight">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.weight')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="weight"
                type="number"
                value={weight}
                onChange={(e) => setWeight(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.agreements">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.agreements')}</Form.Label>
              <SelectMultiple
                isDisabled={!props.allowed.edit_site_visit}
                selected={agreementIds}
                onChange={(e) => setAgreementIds(e ? e.map(i => i.value) : [])}
                url={appConstants.AGREEMENTS_URL + ".json"}
                isClearable={true}
                placeholder={I18n.t('selects.multiple_optional')} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.sector">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sector')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={sectorId}
                onValueChange={(e) => setSectorId(e ? e.value : "")}
                url={appConstants.SECTORS_URL}
                placeholder={I18n.t('selects.single')}
                isClearable={true} />
            </Form.Group>

            <Form.Group as={Col} md={8} className="mb-3" controlid="requestForm.subsector">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.subsectors')}</Form.Label>
              <SelectMultiple
                isDisabled={!props.allowed.edit_site_visit}
                selected={subsectorIds}
                onChange={(e) => setSubsectorIds(e ? e.map(i => i.value) : [])}
                url={appConstants.SUBSECTORS_URL + "?sector_id=" + (sectorId || -1)}
                placeholder={I18n.t('selects.multiple_optional')}
                groupedName='subsectors'
                isDependent={true} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.district">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.district')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={districtId}
                onValueChange={(e) => {
                  setDistrictId(e ? e.value : "")
                  setTerritoryId("")
                  setZoneId("")
                }}
                url={appConstants.DISTRICTS_URL}
                placeholder={I18n.t('selects.single')}
                isClearable={true} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.territory">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.territory')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={territoryId}
                onValueChange={(e) => {
                  setTerritoryId(e ? e.value : "")
                  setZoneId("")
                }}
                url={appConstants.TERRITORIES_BY_DISTRICT_URL + (districtId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.zone">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.zone')}</Form.Label> &nbsp;
              {renderAddZoneButton()}
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={zoneId}
                onValueChange={(e) => setZoneId(e ? e.value : "")}
                url={appConstants.ZONES_BY_TERRITORY_URL + (territoryId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>
          </Row>
          
          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.site_address">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.site_address')}</Form.Label>
                <Form.Control 
                  disabled={!props.allowed.edit_site_visit}
                  name="siteAddress"
                  type="text"
                  value={siteAddress}
                  onChange={(e) => setSiteAddress(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.start_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.start_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_site_visit}
                date={startDate}// momentPropTypes.momentObj or null
                onDateChange={start_date => setStartDate( start_date )} // PropTypes.func.isRequired
                focused={startFocused} // PropTypes.bool
                onFocusChange={({ focused: start_focused }) => setStartFocused( start_focused )} // PropTypes.func.isRequired
                id="start_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.end_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.end_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_site_visit}
                date={endDate}// momentPropTypes.momentObj or null
                onDateChange={end_date => setEndDate( end_date )} // PropTypes.func.isRequired
                focused={endFocused} // PropTypes.bool
                onFocusChange={({ focused: end_focused }) => setEndFocused( end_focused )} // PropTypes.func.isRequired
                id="end_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.actual_start_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.actual_start_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_site_visit}
                date={actualStartDate}// momentPropTypes.momentObj or null
                onDateChange={actual_start_date => setActualStartDate( actual_start_date )} // PropTypes.func.isRequired
                focused={actualStartFocused} // PropTypes.bool
                onFocusChange={({ focused: actual_start_focused }) => setActualStartFocused( actual_start_focused )} // PropTypes.func.isRequired
                id="actual_start_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.actual_end_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.actual_end_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_site_visit}
                date={actualEndDate}// momentPropTypes.momentObj or null
                onDateChange={actual_end_date => setActualEndDate( actual_end_date )} // PropTypes.func.isRequired
                focused={actualEndFocused} // PropTypes.bool
                onFocusChange={({ focused: actual_end_focused }) => setActualEndFocused( actual_end_focused )} // PropTypes.func.isRequired
                id="actual_end_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.latitude">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.latitude')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="latitude"
                type="text"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.longitude">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.longitude')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="longitude"
                type="text"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.partner">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.partner')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="partner"
                type="text"
                value={partner}
                onChange={(e) => setPartner(e.target.value)} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.visit_purpose">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_purpose')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={visitPurpose}
                onValueChange={(e) => setVisitPurpose(e ? e.value : "")}
                url={appConstants.VISIT_PURPOSES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.attempt">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.attempt')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={attempt}
                onValueChange={(e) => setAttempt(e ? e.value : "")}
                url={appConstants.ATTEMPTS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} controlId="requestForm.request_status">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_status')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={requestStatus}
                onValueChange={(e) => setRequestStatus(e.value)}
                url={appConstants.EVENT_STATUSES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.monitoring_cycle">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitoring_cycle')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={monitoringCycleId}
                onValueChange={(e) => setMonitoringCycleId(e ? e.value : "")}
                url={appConstants.MONITORING_CYCLES_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
          </Row>
          { requestStatus == 'pending' ?
          (
          <Row>
            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.postponed_reason">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={requestJustification}
                onValueChange={(e) => setRequestJustification(e ? e.value : "")}
                url={appConstants.JUSTIFICATIONS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.postponed_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification_details')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="requestJustificationDetails"
                type="text"
                value={requestJustificationDetails}
                onChange={(e) => setRequestJustificationDetails(e.target.value)} />
            </Form.Group>
          </Row> ) : ""
          }

          <Form.Group  className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.edit_site_visit} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            {renderDuplicateButton()} &nbsp;
            {id ? renderDeleteButton() : renderCancelButton()}
          </Form.Group>
        </Tab>
        <Tab eventKey="ipInfo" title={I18n.t('navigation.ip_information_request')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={ipSiteActive ? 3 : 1} className="mb-3" controlid="requestForm.ip_site_active">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_site_active')}<span className="text-muted">*</span></Form.Label>
              <Form.Check
                disabled={!props.allowed.edit_site_visit}
                type="switch"
                name="ipSiteActive"
                checked={ipSiteActive}
                value={true}
                onChange={() => setIpSiteActive(!ipSiteActive)}
                bsPrefix="ipSiteActive-switch" />
            </Form.Group>
            {!ipSiteActive ?
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_site_inactive_reason">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_site_inactive_reason')}<span className="text-muted">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit || ipSiteActive}
                  name="ipSiteInactiveReason"
                  type="text"
                  value={ipSiteInactiveReason}
                  onChange={(e) => setIpSiteInactiveReason(e.target.value)} />
            </Form.Group>
            : ""}
            {hasFacilitySector ?
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.facility_type">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.facility_type')}<span className="text-muted">*</span></Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={facilityType}
                onValueChange={(e) => setFacilityType(e ? e.value : "")}
                url={appConstants.FACILITY_TYPES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
            : ""}
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_liason_name">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_liason_name')}<span className="text-muted">*</span></Form.Label>
                <Form.Control 
                  disabled={!props.allowed.edit_site_visit}
                  name="ipLiasonName"
                  type="text"
                  value={ipLiasonName}
                  onChange={(e) => setIpLiasonName(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.ip_liason_email">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_liason_email')}<span className="text-muted">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="ipLiasonEmail"
                  type="text"
                  value={ipLiasonContact}
                  onChange={(e) => setIpLiasonContact(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_liason_phone">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_liason_phone')}<span className="text-muted">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="ipLiasonPhone"
                  type="text"
                  value={ipLiasonPhone}
                  onChange={(e) => setIpLiasonPhone(e.target.value)} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_name_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_name_1')}<span className="text-muted">*</span></Form.Label>
                <Form.Control 
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderNameOne"
                  type="text"
                  value={communityLeaderNameOne}
                  onChange={(e) => setCommunityLeaderNameOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_contact_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_contact_1')}<span className="text-muted">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderContactOne"
                  type="text"
                  value={communityLeaderContactOne}
                  onChange={(e) => setCommunityLeaderContactOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_name_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_name_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderNameTwo"
                  type="text"
                  value={communityLeaderNameTwo}
                  onChange={(e) => setCommunityLeaderNameTwo(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_contact_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_contact_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderContactTwo"
                  type="text"
                  value={communityLeaderContactTwo}
                  onChange={(e) => setCommunityLeaderContactTwo(e.target.value)} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_name_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_name_1')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerNameOne"
                  type="text"
                  value={communityVolunteerNameOne}
                  onChange={(e) => setCommunityVolunteerNameOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_contact_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_contact_1')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerContactOne" 
                  type="text"
                  value={communityVolunteerContactOne}
                  onChange={(e) => setCommunityVolunteerContactOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_name_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_name_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerNameTwo"
                  type="text"
                  value={communityVolunteerNameTwo}
                  onChange={(e) => setCommunityVolunteerNameTwo(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_contact_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_contact_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerContactTwo"
                  type="text"
                  value={communityVolunteerContactTwo}
                  onChange={(e) => setCommunityVolunteerContactTwo(e.target.value)} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={10} className="mb-3" controlid="requestForm.ip_tab_ip_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_tab_ip_comments')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="ipTabIpComments"
                  as="textarea"
                  rows="3"
                  value={ipTabIpComments}
                  onChange={(e) => setIpTabIpComments(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3 mt-2" controlid="requestForm.report_upload_button">
              <Button variant="outline-secondary" size="md" className="mt-4 ms-3" onClick={()=>setShowAddResourceModal(true)}>{I18n.t('activerecord.attributes.monitoring_request.upload_resource')}</Button>
            </Form.Group>
          </Row>
          <Form.Group  className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.edit_site_visit} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          </Form.Group>
        </Tab>
        { props.allowed.dcp_tab ?
          (
          <Tab eventKey="data_collection_partner" title={I18n.t('navigation.coordination_tab')}>
            <Row className="mt-3">
              <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.user">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.user')}</Form.Label>
                <SelectSingle
                  isDisabled={!props.allowed.edit_site_visit}
                  selected={userId}
                  onValueChange={(e) => setUserId(e ? e.value : "")}
                  url={appConstants.DCP_MANAGERS_URL}
                  isClearable={true}
                  placeholder={I18n.t('selects.single')} />
              </Form.Group>

              <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.data_collection_partner">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.office')}</Form.Label>
                <SelectSingle
                  isDisabled={!props.allowed.edit_site_visit}
                  selected={officeId}
                  onValueChange={(e) => setOfficeId(e ? e.value : "")}
                  url={appConstants.OFFICES_URL}
                  isClearable={true}
                  placeholder={I18n.t('selects.single')} />
              </Form.Group>

              <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.visitType">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_type')}</Form.Label>
                <SelectSingle
                  isDisabled={!props.allowed.edit_site_visit_meta}
                  selected={visitType}
                  onValueChange={(e) => setVisitType(e ? e.value : "")}
                  url={appConstants.VISIT_TYPES_URL}
                  isClearable={true}
                  placeholder={I18n.t('selects.single')} />
              </Form.Group>

              <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.tpm_leader">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.tpm_leader')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="tpmLeader"
                  type="text"
                  value={tpmLeader}
                  onChange={(e) => setTpmLeader(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.data_sync_due">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.data_sync_due')}</Form.Label><br/>
                <SingleDatePicker
                  disabled={!props.allowed.edit_site_visit}
                  date={dataSyncDue}// momentPropTypes.momentObj or null
                  onDateChange={data_sync_due => setDataSyncDue( data_sync_due )} // PropTypes.func.isRequired
                  focused={dataSyncDueFocused} // PropTypes.bool
                  onFocusChange={({ focused: data_sync_due_focused }) => setDataSyncDueFocused( data_sync_due_focused )} // PropTypes.func.isRequired
                  id="data_sync_due" // PropTypes.string.isRequired,
                  isOutsideRange={()=>false}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md={6} className="mb-3">
                <Card>
                  <Row className="p-3">
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.monitor_1">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitor_1')}</Form.Label>
                      <Form.Control
                        disabled={!props.allowed.edit_site_visit}
                        name="monitor1"
                        type="text"
                        value={monitor1}
                        onChange={(e) => setMonitor1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.fulcrum_account_1">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.fulcrum_account_1')}</Form.Label>
                      <SelectSingle
                        isDisabled={!props.allowed.edit_site_visit}
                        selected={fulcrumAccount1Id}
                        onValueChange={(e) => setFulcrumAccount1Id(e ? e.value : "")}
                        url={`${appConstants.FULCRUM_ACCOUNTS_FOR_DCP_URL}${String(officeId)}`}
                        placeholder={I18n.t('selects.single')} />
                    </Form.Group>
                  </Row>
                </Card>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-3">
                <Card>
                  <Row className="p-3">
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.monitor_2">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitor_2')}</Form.Label>
                      <Form.Control
                        disabled={!props.allowed.edit_site_visit}
                        name="monitor2"
                        type="text"
                        value={monitor2}
                        onChange={(e) => setMonitor2(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.fulcrum_account_2">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.fulcrum_account_2')}</Form.Label>
                      <SelectSingle
                        isDisabled={!props.allowed.edit_site_visit}
                        selected={fulcrumAccount2Id}
                        onValueChange={(e) => setFulcrumAccount2Id(e ? e.value : "")}
                        url={`${appConstants.FULCRUM_ACCOUNTS_FOR_DCP_URL}${String(officeId)}`}
                        placeholder={I18n.t('selects.single')} />
                    </Form.Group>
                  </Row>
                </Card>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md={6} className="mb-3">
                <Card>
                  <Row className="p-3">
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.monitor_3">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitor_3')}</Form.Label>
                      <Form.Control
                        disabled={!props.allowed.edit_site_visit}
                        name="monitor3"
                        type="text"
                        value={monitor3}
                        onChange={(e) => setMonitor3(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.fulcrum_account_3">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.fulcrum_account_3')}</Form.Label>
                      <SelectSingle
                        isDisabled={!props.allowed.edit_site_visit}
                        selected={fulcrumAccount3Id}
                        onValueChange={(e) => setFulcrumAccount3Id(e ? e.value : "")}
                        url={`${appConstants.FULCRUM_ACCOUNTS_FOR_DCP_URL}${String(officeId)}`}
                        placeholder={I18n.t('selects.single')} />
                    </Form.Group>
                  </Row>
                </Card>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-3">
                <Card>
                  <Row className="p-3">
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.monitor_4">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitor_4')}</Form.Label>
                      <Form.Control
                        disabled={!props.allowed.edit_site_visit}
                        name="monitor4"
                        type="text"
                        value={monitor4}
                        onChange={(e) => setMonitor4(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.fulcrum_account_4">
                      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.fulcrum_account_4')}</Form.Label>
                      <SelectSingle
                        isDisabled={!props.allowed.edit_site_visit}
                        selected={fulcrumAccount4Id}
                        onValueChange={(e) => setFulcrumAccount4Id(e ? e.value : "")}
                        url={`${appConstants.FULCRUM_ACCOUNTS_FOR_DCP_URL}${String(officeId)}`}
                        placeholder={I18n.t('selects.single')} />
                    </Form.Group>
                  </Row>
                </Card>
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlid="requestForm.buttons">
              <Button disabled={!props.allowed.edit_site_visit} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            </Form.Group>
          </Tab>
          ) : ""
        }
        { props.allowed.dcp_review_tab ?
          (
          <Tab eventKey="dcp_review" title={I18n.t('navigation.dcp_review_tab')} className="mb-3">
            <Row className="mt-3">
              <Form.Group as={Col} md={2} controlid="requestForm.data_reviewed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.data_reviewed')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="dataReviewed"
                  checked={dataReviewed}
                  value={true}
                  onChange={() => setDataReviewed(!dataReviewed)}
                  bsPrefix="dataReviewed-switch" />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.issue_identified" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.issue_identified')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="issueIdentified"
                  checked={issueIdentified}
                  value={true}
                  onChange={() => setIssueIdentified(!issueIdentified)}
                  bsPrefix="issueIdentified-switch" />
              </Form.Group>
              <Form.Group as={Col} md={4} controlid="requestForm.monitoring_issue" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitoring_issue')}</Form.Label>
                <SelectSingle
                  isDisabled={!props.allowed.edit_site_visit}
                  selected={monitoringIssue}
                  onValueChange={(e) => setMonitoringIssue(e ? e.value : "")}
                  url={appConstants.MONITORING_ISSUES_URL}
                  placeholder={I18n.t('selects.single')}
                  isClearable={true} />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.issue_fixed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.issue_fixed')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="issueFixed"
                  checked={issueFixed}
                  value={true}
                  onChange={() => setIssueFixed(!issueFixed)}
                  bsPrefix="issueFixed-switch" />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.date_fixed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.date_fixed')}</Form.Label><br/>
                <SingleDatePicker
                  disabled={!props.allowed.edit_site_visit}
                  date={dateFixed}// momentPropTypes.momentObj or null
                  onDateChange={date_fixed => setDateFixed( date_fixed )} // PropTypes.func.isRequired
                  focused={fixedFocused} // PropTypes.bool
                  onFocusChange={({ focused: fixed_focused }) => setFixedFocused( fixed_focused )} // PropTypes.func.isRequired
                  id="date_fixed" // PropTypes.string.isRequired,
                  isOutsideRange={()=>false}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={6} controlid="requestForm.other_issues" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.other_issues')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="otherIssues"
                  as="textarea"
                  rows={3}
                  value={otherIssues}
                  onChange={(e) => setOtherIssues(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md={6} controlid="requestForm.action_point" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.action_point')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="actionPoint"
                  as="textarea"
                  rows={3}
                  value={actionPoint}
                  onChange={(e) => setActionPoint(e.target.value)} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={12} controlid="requestForm.review_comments" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.review_comments')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="reviewComments"
                  as="textarea"
                  rows={3}
                  value={reviewComments}
                  onChange={(e) => setReviewComments(e.target.value)} />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlid="requestForm.buttons">
              <Button disabled={!props.allowed.edit_site_visit} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            </Form.Group>
          </Tab>
          ) : ""
        }
        { props.allowed.smlp2_review_tab ?
          (
          <Tab eventKey="smlp2_review" title={I18n.t('navigation.smlp2_review_tab')} className="mb-3">
            <Row className="mt-3">
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_data_reviewed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_data_reviewed')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="smlp2DataReviewed"
                  checked={smlp2DataReviewed}
                  value={true}
                  onChange={() => setSmlp2DataReviewed(!smlp2DataReviewed)}
                  bsPrefix="smlp2DataReviewed-switch" />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_issue_identified" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_issue_identified')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="issueIdentifiedSmlpl2"
                  checked={smlp2IssueIdentified}
                  value={true}
                  onChange={() => setSmlp2IssueIdentified(!smlp2IssueIdentified)}
                  bsPrefix="smlp2IssueIdentified-switch" />
              </Form.Group>
              <Form.Group as={Col} md={4} controlid="requestForm.smlp2_monitoring_issue" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_monitoring_issue')}</Form.Label>
                <SelectSingle
                  isDisabled={!props.allowed.edit_site_visit}
                  selected={smlp2MonitoringIssue}
                  onValueChange={(e) => setSmlp2MonitoringIssue(e ? e.value : "")}
                  url={appConstants.MONITORING_ISSUES_URL}
                  placeholder={I18n.t('selects.single')}
                  isClearable={true} />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_issue_fixed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_issue_fixed')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="smlp2IssueFixed"
                  checked={smlp2IssueFixed}
                  value={true}
                  onChange={() => setSmlp2IssueFixed(!smlp2IssueFixed)}
                  bsPrefix="smlp2IssueFixed-switch" />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_date_fixed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_date_fixed')}</Form.Label><br/>
                <SingleDatePicker
                  disabled={!props.allowed.edit_site_visit}
                  date={smlp2DateFixed}// momentPropTypes.momentObj or null
                  onDateChange={smlp2_date_fixed => setSmlp2DateFixed( smlp2_date_fixed )} // PropTypes.func.isRequired
                  focused={smlp2FixedFocused} // PropTypes.bool
                  onFocusChange={({ focused: smlp2_fixed_focused }) => setSmlp2FixedFocused( smlp2_fixed_focused )} // PropTypes.func.isRequired
                  id="date_fixed" // PropTypes.string.isRequired,
                  isOutsideRange={()=>false}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={6} controlid="requestForm.smlp2_other_issues" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_other_issues')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="smlp2OtherIssues"
                  as="textarea"
                  rows={3}
                  value={smlp2OtherIssues}
                  onChange={(e) => setSmlp2OtherIssues(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md={6} controlid="requestForm.smlp2_action_point" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_action_point')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="smlp2ActionPoint"
                  as="textarea"
                  rows={3}
                  value={smlp2ActionPoint}
                  onChange={(e) => setSmlp2ActionPoint(e.target.value)} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={12} controlid="requestForm.smlp2_review_comments" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_review_comments')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="smlp2ReviewComments"
                  as="textarea"
                  rows={3}
                  value={smlp2ReviewComments}
                  onChange={(e) => setSmlp2ReviewComments(e.target.value)} />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlid="requestForm.buttons">
              <Button disabled={!props.allowed.edit_site_visit} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            </Form.Group>
          </Tab>
          ) : ""
        }
        <Tab eventKey="report" title={I18n.t('navigation.report_tab')} className="mb-3">
        { props.allowed.report_tab ?
          (
            <Row className="mt-3">
              <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.report_writer">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.report_writer')}</Form.Label>
                <SelectSingle
                  isDisabled={!props.allowed.edit_site_visit}
                  selected={reportWriterId}
                  onValueChange={(e) => setReportWriterId(e ? e.value : "")}
                  url={appConstants.REPORT_WRITERS_URL}
                  isClearable={true}
                  placeholder={I18n.t('selects.single')} />
              </Form.Group>

              <Form.Group as={Col} md={2} className="mb-3 pe-5" controlid="requestForm.report_due">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.report_due')}</Form.Label><br/>
                  <SingleDatePicker
                    disabled={!props.allowed.edit_site_visit}
                    date={reportDue}// momentPropTypes.momentObj or null
                    onDateChange={report_due => setReportDue( report_due )} // PropTypes.func.isRequired
                    focused={reportDueFocused} // PropTypes.bool
                    onFocusChange={({ focused: report_due_focused }) => setReportDueFocused( report_due_focused )} // PropTypes.func.isRequired
                    id="report_due_focused" // PropTypes.string.isRequired,
                    isOutsideRange={()=>false}
                  />
              </Form.Group>

              <Form.Group as={Col} md={2} className="mb-3 px-5" controlid="requestForm.ip_share_due">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_share_due')}</Form.Label><br/>
                  <SingleDatePicker
                    disabled={!props.allowed.edit_site_visit}
                    date={ipShareDue}// momentPropTypes.momentObj or null
                    onDateChange={ip_share_due => setIpShareDue( ip_share_due )} // PropTypes.func.isRequired
                    focused={ipShareDueFocused} // PropTypes.bool
                    onFocusChange={({ focused: ip_share_due_focused }) => setIpShareDueFocused( ip_share_due_focused )} // PropTypes.func.isRequired
                    id="ip_share_due_focused" // PropTypes.string.isRequired,
                    isOutsideRange={()=>false}
                  />
              </Form.Group>

              <Form.Group as={Col} md={2} className="mb-3 px-5" controlid="requestForm.sent_to_ip">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sent_to_ip')}</Form.Label><br/>
                <SingleDatePicker
                  disabled={!props.allowed.edit_site_visit}
                  date={sentToIp}// momentPropTypes.momentObj or null
                  onDateChange={sent_to_ip => setSentToIp( sent_to_ip )} // PropTypes.func.isRequired
                  focused={sentToIpFocused} // PropTypes.bool
                  onFocusChange={({ focused: sent_to_ip_focused }) => setSentToIpFocused( sent_to_ip_focused )} // PropTypes.func.isRequired
                  id="sent_to_ip" // PropTypes.string.isRequired,
                  isOutsideRange={()=>false}
                />
              </Form.Group>
            </Row>
          ) : ""
        } 

          <Row className="mt-3">
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_feedback_due">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_feedback_due')}</Form.Label><br/>
                <SingleDatePicker
                  disabled={!props.allowed.edit_site_visit}
                  date={ipFeedbackDue}// momentPropTypes.momentObj or null
                  onDateChange={ip_feedback_due => setIpFeedbackDue( ip_feedback_due )} // PropTypes.func.isRequired
                  focused={ipFeedbackDueFocused} // PropTypes.bool
                  onFocusChange={({ focused: ip_feedback_due_focused }) => setIpFeedbackDueFocused( ip_feedback_due_focused )} // PropTypes.func.isRequired
                  id="ip_feedback_due_focused" // PropTypes.string.isRequired,
                  isOutsideRange={()=>false}
                />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_feedback_received">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_feedback_received')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="ipFeedbackReceived"
                  checked={ipFeedbackReceived}
                  value={true}
                  onChange={() => setIpFeedbackReceived(!ipFeedbackReceived)}
                  bsPrefix="ipFeedbackReceived-switch" />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3 mt-2" controlid="requestForm.report_upload_button">
              <Button variant="outline-secondary" size="md" className="mt-4 ms-3" onClick={()=>setShowAddResourceModal(true)}>{I18n.t('activerecord.attributes.monitoring_request.upload_resource')}</Button>
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.ip_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_comments')} &nbsp; {ipCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(ipCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {ipCommentsUpdater}</span> : ""}</Form.Label><br/>
              <Form.Control
                disabled={!props.allowed.ip_comments}
                as="textarea"
                name="ip_comments"
                value={ipComments}
                onChange={(e) => setIpComments(e.target.value)}
                rows="4" />
            </Form.Group>
          </Row>

          <Row className="mt-3">
            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.bha_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.bha_comments')} &nbsp; {bhaCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(bhaCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {bhaCommentsUpdater}</span> : ""}</Form.Label><br/>
              <Form.Control
                disabled={!props.allowed.bha_comments}
                as="textarea"
                name="bha_comments"
                value={bhaComments}
                onChange={(e) => setBhaComments(e.target.value)}
                rows="4" />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.report_tab_smlp2_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.report_tab_smlp2_comments')} &nbsp; {reportTabSmlp2CommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(reportTabSmlp2CommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {reportTabSmlp2CommentsUpdater}</span> : ""}</Form.Label><br/>
              <Form.Control
                disabled={!props.allowed.smlp2_comments}
                as="textarea"
                name="report_tab_smlp2_comments"
                value={reportTabSmlp2Comments}
                onChange={(e) => setReportTabSmlp2Comments(e.target.value)}
                rows="4" />
            </Form.Group>
          </Row>
          <h6>{I18n.t('activerecord.attributes.monitoring_request.flash_report')}</h6>
          <hr />
          <Row>
            <Form.Group as={Col} md={2} className="mb-3 mt-2">
              { renderFlashReportUploaded() }
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3 mt-2" controlid="requestForm.flash_report_upload_button">
              <Button variant="outline-secondary" size="md" className="mt-4 ms-3" onClick={()=>setShowAddFlashReportModal(true)}>{I18n.t('activerecord.attributes.monitoring_request.upload_flash_report')}</Button>
            </Form.Group>

            <Form.Group as={Col} md={2} controlid="requestForm.flash_report_ready" className="mb-3" >
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.flash_report_ready')}</Form.Label>
              <Form.Check
                disabled={!props.allowed.edit_site_visit}
                type="switch"
                name="flashReportReady"
                checked={flashReportReady}
                value={true}
                onChange={() => (setFlashReportReady(!flashReportReady), !flashReportStatus ? setFlashReportStatus('fr_created') : "")}
                bsPrefix="flashReportReady-switch" />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.flash_report_status">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.flash_report_status')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={flashReportStatus}
                onValueChange={(e) => setFlashReportStatus(e ? e.value : "")}
                url={appConstants.FLASH_REPORT_STATUSES_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.ip_flash_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_flash_comments')}  &nbsp; {ipFlashCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(ipFlashCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {ipFlashCommentsUpdater}</span> : ""}</Form.Label><br/>
              <Form.Control
                disabled={true}
                as="textarea"
                name="ip_flash_comments"
                value={ipFlashComments}
                onChange={(e) => setIpFlashComments(e.target.value)}
                rows="4" />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.bha_flash_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.bha_flash_comments')}  &nbsp; {bhaFlashCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(bhaFlashCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {bhaFlashCommentsUpdater}</span> : ""}</Form.Label><br/>
              <Form.Control
                disabled={true}
                as="textarea"
                name="bha_flash_comments"
                value={bhaFlashComments}
                onChange={(e) => setBhaFlashComments(e.target.value)}
                rows="4" />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.smlp2_flash_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_flash_comments')}  &nbsp; {smlp2FlashCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(smlp2FlashCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {smlp2FlashCommentsUpdater}</span> : ""}</Form.Label><br/>
              <Form.Control
                disabled={true}
                as="textarea"
                name="smlp2_flash_comments"
                value={smlp2FlashComments}
                onChange={(e) => setSmlp2FlashComments(e.target.value)}
                rows="4" />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.submit_site_visit} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          </Form.Group>
        </Tab>

        { renderFulcrumTab() }
        { resourcesCount ?
          (
            <Tab eventKey="resources" title={I18n.t('navigation.resources_tab')} className="mb-3">
              <div className="mt-3">
                <VisitResourceTable siteVisitId={props.id} refresh={showAddResourceModal} canDownload={props.allowed.download_report} />
              </div>
            </Tab>
          ) : ""
        }
      </Tabs>
    </Form>
  )
}