import React from 'react'
import styled from 'styled-components'
import { BiAlarm, BiAlarmExclamation } from 'react-icons/bi'
import moment from 'moment'
import { BsFlagFill } from 'react-icons/bs'

export const chartColors = ["#8CFDB4","#ECAFB5","#89C7FF","#301171","#1A8413","#899214","#4559C7","#A60B46",
    "#E17840","#25802B","#26767E","#DCCF49","#F4DC04",  "#3AA4E4","#26B52F","#F68EA8","#D10D79","#86C5DA","#627C9B",
    "#CFE7A1","#6A7D3C","#A441D5","#DBC7FF",'#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', 
    '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', 
    '#000075', '#808080',]

export const scoreColors = {
  "0": {name: "0.0 - 0.49", color: "#f40606"},
  "1": {name: "0.5 - 1.49", color: "#dc5805"},
  "2": {name: "1.5 - 2.49", color: "#c49804"},
  "3": {name: "2.5 - 3.49", color: "#91ac03"},
  "4": {name: "3.5 - 4.49", color: "#459303"},
  "5": {name: "4.5 - 5.0", color: "#0b7b02"}
}

export const badgeAttributes = {
  "Market Scan": {class: "bg-danger", text: "MS"},
  "Pre-Activity": {class: "bg-secondary", text: "PA"},
  "Community Monitoring": {class: "bg-warning", text: "CM"},
  "Call Center": {class: "bg-info", text: "CC"},
  "BHA-Identified Thematic Site Visit": {class: "bg-dark", text: "BHAT"},
  "TPM": {class: "bg-primary", text: "TPM"}
}

export const tpmQuestionFields = ["indicator", "sector", "subsector", "respondent", "key_word", "data_name", "data_group",
  "label", "question_number", "question", "question_type", "badge_condition", "comments", "answer1", "answer2", "answer3",
  "answer4", "answer5", "answer6", "answer7", "answer8", "answer9", "answer10", "answer11", "answer12", "answer13",
  "answer14", "answer15",]

export const facilitySectors = ["Health", "Nutrition"]

export const appConstants = {
  MONITORING_EVENT_URL: '/tpm/monitoring_requests/',
  PLANNING_URL: '/tpm/planning',
  ARCHIVE_URL: '/tpm/planning/archive.json',
  RESULTS_URL: '/tpm/results.json',
  CALENDAR_URL: '/tpm/events_calendar/',
  REVIEW_BOARD_URL: '/tpm/visits',
  FLASH_BOARD_URL: '/tpm/flash_reporting',
  USERS_URL: '/users.json',
  DCP_MANAGERS_URL: '/users.json?job=ifm',
  MONITORS_URL: '/users.json?job=monitor',
  REVIEWERS_URL: '/users.json?job=reviewer',
  USAID_USERS_URL: '/users.json?job=security',
  COMPLETED_VISITS_DIGEST_USERS_URL: '/users.json?job=completed_visits_digest',
  COMPLETED_LESSONS_DIGEST_USERS_URL: '/users.json?job=completed_lessons_digest',
  REPORT_WRITERS_URL: '/users.json?job=report_writer',
  ADMINS_URL: '/users.json?job=admin',
  EVENT_STATUSES_URL: '/tpm/request_statuses',
  ARCHIVE_STATUSES_URL: '/tpm/request_statuses?archive=true',
  IFM_STATUSES_URL: '/tpm/request_statuses?ifm=true',
  EVENT_MODES_URL: '/tpm/request_modes',
  ACTIVITIES_URL: '/tpm/implementing_partners',
  IMPLEMENTING_PARTNERS_URL: '/tpm/cooperating_partners',
  IPS_BY_ACTIVITY_URL: '/tpm/ips_by_activity/',
  IPS_URL: '/tpm/cooperating_partners.json',
  ACTIVITIES_BY_IP_URL: '/tpm/activities_by_ip/',
  GOVERNORATES_URL: '/tpm/governorates.json',
  DISTRICTS_URL: '/tpm/districts.json',
  DISTRICTS_BY_GOVERNORATE_URL: '/tpm/districts_by_governorate/',
  TERRITORIES_URL: '/tpm/territories.json',
  TERRITORIES_BY_DISTRICT_URL: '/tpm/territories_by_district/',
  ZONES_URL: '/tpm/zones.json',
  ZONES_BY_TERRITORY_URL: '/tpm/zones_by_territory/',
  SECTORS_URL: '/tpm/sectors.json',
  SUBSECTORS_URL: '/tpm/subsectors.json',
  RESOURCES_FOR_MONITORING_REQUEST_URL: '/kmp/resources_for_monitoring_request/',
  SITE_VISIT_RESOURCES_URL: '/kmp/site_visit_resources/',
  IMAGES_FOR_MONITORING_REQUEST_URL: '/kmp/images_for_monitoring_request/',
  FILTERED_RESOURCES_URL: '/kmp/filtered_resources',
  JUSTIFICATIONS_URL: '/tpm/request_justifications',
  ATTEMPTS_URL: '/tpm/attempts',
  YEARS_URL: '/tpm/years',
  TIME_INTERVALS_URL: '/tpm/time_intervals',
  COLLECTIONS_URL: '/kmp/collections/',
  RESOURCES_URL: '/kmp/resources/',
  SEARCH_URL: '/kmp/search/',
  COLLECTIONS_JSON_URL: '/kmp/collections.json',
  RESOURCES_JSON_URL: '/kmp/resources.json',
  GROUPED_TAGS_URL: '/kmp/grouped_tags/',
  MONITORING_TAGS_URL: '/kmp/grouped_tags?monitoring_request_tags=true',
  RESOURCE_TYPE_TAGS_URL: '/kmp/tags.json?tag_type=resource_type',
  REQUESTS_FOR_RESOURCES_URL: '/tpm/requests_for_resources/',
  IMPORT_EVENTS_URL: '/tpm/bulk_import',
  OFFICES_URL: '/tpm/offices.json',
  NEW_RESOURCE_URL: '/kmp/resources/new',
  NEW_COLLECTION_URL: '/kmp/collections/new',
  FLAGS_URL: '/tpm/lessons',
  FLAGGABLE_REQUESTS_URL: '/tpm/flaggable_requests.json',
  FLAG_PRIORITIES_URL: '/tpm/flag_priorities.json',
  FLAG_STATUSES_URL: '/tpm/flag_statuses.json',
  FULCRUM_URL: 'https://web.fulcrumapp.com/',
  TPM_QUESTIONS_URL: '/tpm/tpm_questions/',
  TPM_ANSWER_TYPES_URL: '/tpm/answer_types',
  FULCRUM_FORMS_URL: '/tpm/fulcrum_forms',
  QUESTION_IMPORT_URL: '/tpm/questions_import',
  IP_PLANNING_URL: '/tpm/ip_planning.json',
  IP_PLANS_URL: '/tpm/ip_plans_list.json',
  IP_PLAN_URL: '/tpm/ip_plans/',
  IP_PLAN_STATUSES_URL: '/tpm/ip_plan_statuses.json',
  RESOURCES_FOR_IP_PLAN_URL: '/kmp/ip_plan_resources/',
  IP_PLAN_VISITS_URL: '/tpm/ip_plan_visits/',
  AGREEMENTS_URL: '/tpm/agreements',
  MONITORING_REQUESTS_URL: '/tpm/monitoring_requests',
  MONITORING_ISSUES_URL: '/tpm/monitoring_issues',
  FORMS_FOR_VISIT_URL: '/tpm/forms_for_visit',
  MONITORING_CYCLES_URL: '/tpm/monitoring_cycles.json',
  FULCRUM_ACCOUNTS_URL: '/tpm/fulcrum_accounts.json',
  FULCRUM_ACCOUNTS_FOR_DCP_URL: '/tpm/fulcrum_accounts_for_dcp/',
  ISSUE_TYPES_URL: '/tpm/issue_types',
  SURVEY_TYPES_URL: '/tpm/survey_types',
  VISIT_TYPES_URL: '/tpm/visit_types',
  VISIT_PURPOSES_URL: '/tpm/visit_purposes',
  COMPLETED_VISITS_URL: '/tpm/completed_visits',
  SEND_COMPLETED_VISITS_URL: '/tpm/send_completed_visits',
  FLASH_REPORT_STATUSES_URL: '/tpm/flash_report_statuses.json',
  LEARNING_MODULE_STATUSES_URL: '/tpm/learning_module_statuses.json',
  LESSON_THEMES_URL: '/tpm/lesson_themes.json',
  QUARTERS_URL: '/tpm/quarters',
  SEMESTERS_URL: '/tpm/semesters',
  LESSON_RESOURCES_URL: '/kmp/lesson_resources/',
  LESSONS_LIST_URL: '/tpm/lessons_list.json',
  COMMENTS_URL: '/tpm/comments',
  OBJECT_COMMENTS_URL: '/tpm/object_comments',
  COMPLETED_LESSONS_URL: '/tpm/completed_lessons',
  SEND_COMPLETED_LESSONS_URL: '/tpm/send_completed_lessons',
  NOTIFICATIONS_LIST_URL: '/tpm/notifications_list',
  STALE_NOTIFICATIONS_URL: '/tpm/stale_notifications',
  VIEWED_NOTIFICATIONS_URL: '/tpm/viewed_notifications',
  USER_PREFERENCES_URL: '/users/update_email_preferences',
  FACILITY_TYPES_URL: '/tpm/facility_types',
}

export const setResource = (e) => {
  const resource = {
    id: parseInt(e.id),
    name: e.name,
    url: e.url,
    description: e.description,
    is_public: e.is_public,
    office_name: e.office_name,
    resource_type_name: e.resource_type_name,
  }
  return resource
}

export const setLesson = (e) => {
  const lesson = {
    id: parseInt(e.id),
    name: e.name,
    issue: e.issue,
    date_entered: e.date_entered,
    proposed_action: e.proposed_action,
    number_of_changes: e.number_of_changes,
    survey_type: e.survey_type,
    description: e.description,
    issue_type_id: parseInt(e.issue_type_id),
    monitoring_request_id: e.monitoring_request_id,
    monitoring_request_visit_date: e.monitoring_request_visit_date,
    monitoring_request_report_upload_date: e.monitoring_request_report_upload_date,
    monitoring_request_ip_feedback_received: e.monitoring_request_ip_feedback_received,
    due_date: e.due_date,
    user_id: e.user_id,
    flag_priority: e.flag_priority,
    flag_status: e.flag_status,
  }
  return lesson
}

export const setIpPlan = (e) => {
  const plan = {
    id: parseInt(e.id),
    activity_id: e.activity_id,
    activity_name: e.activity_name,
    ip_plan_status: e.ip_plan_status,
    start_date: e.start_date,
    end_date: e.end_date,
    initial_meeting_date: e.initial_meeting_date,
    sites_requested_date: e.sites_requested_date,
    sites_provided_date: e.sites_provided_date,
    sites_proposed_date: e.sites_proposed_date,
    general_info: e.general_info,
    poc: e.poc,
    description: e.description,
    postponed_reason: e.postponed_reason,
    assignee_id: e.assignee,
    assignee_name: e.assignee_name,
    office_id: e.office_id,
    office_name: e.office_name,
    sector_id: e.sector_id,
    sector_name: e.sector_name,
    subsectors: e.subsectors,
    tags: e.tags,
    agreements: e.agreements
  }
  return plan
}

export const localTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const YellowFlag = styled(BsFlagFill)`
  color: #ffde20;
  `;

const OrangeFlag = styled(BsFlagFill)`
  color: #f48000;
  `;

const RedFlag = styled(BsFlagFill)`
  color: #d90000;
  `;

const GreenTimeDot = styled(BiAlarm)`
  color: green;
  `;

const YellowTimeDot = styled(BiAlarm)`
  color: gold;
  `;

const OrangeTimeDot = styled(BiAlarm)`
  color: orange;
  `;

const RedTimeDot = styled(BiAlarmExclamation)`
  color: red;
  `;

export const renderTimeDots = (startDate, status) => {
  if (status != 'submitted') {
    let dayDiff = 0
    const now = moment()
    if (startDate) {
      const start = moment(startDate)
      dayDiff = now.diff(start, 'days')
    }

    let dots = [];
    if (dayDiff <= 1) {
      _.times(1, (i) => {
        dots.push(<GreenTimeDot key={i}></GreenTimeDot>)
      })
    } else if (dayDiff <= 2 ) {
      _.times(2, (i) => {
        dots.push(<GreenTimeDot key={i}></GreenTimeDot>)
      })
    } else if (dayDiff > 2 && dayDiff <= 4) {
      _.times(3, (i) => {
        dots.push(<YellowTimeDot key={i}></YellowTimeDot>)
      })
    } else if (dayDiff > 4) {
      _.times(5, (i) => {
        dots.push(<RedTimeDot key={i}></RedTimeDot>)
      })
    }

    return (
      <>
      {dots}
      </>
    )
  }
}

export const getFileExtension = (filename) => {
  return filename.split('.').pop();
}

export const isUnacceptableFileType = (filename) => {
  return ['sql', 'bin', 'exe'].indexOf(getFileExtension(filename)) !== -1
}

export const currentFiscalYearStartDate = () => {
  const today = new Date()
  let year = today.getFullYear()
  if (today.getMonth() < 9) {
    year = year - 1
  }
  return new Date(year, 9, 15)
}

export const userTypeBadge = (userType) => {
  const key = {
    "admin": {color: "primary", text: "Admin"},
    "second_review_admin": {color: "primary", text: "SMLP2"},
    "cop_admin": {color: "secondary", text: "IP"},
    "security": {color: "danger", text: "USAID"}
  }
  return key[userType]
}