import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, Tab, Tabs, Modal } from 'react-bootstrap'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import ZoneForm from './ZoneForm.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone';
import { appConstants, localTimezone } from 'utils/constants.js'
import { BiPlus } from 'react-icons/bi'
import VisitResourceTable from '../tables/VisitResourceTable'
import ResourceForm from '../forms/ResourceForm.js'


export default function FlashReportForm(props) {
  const [id, setId] = useState(props.id || '')
  const [activityId, setActivityId] = useState('')
  const [officeId, setOfficeId] = useState('')
  const [ipPlanId, setIpPlanId] = useState('')
  const [agreementIds, setAgreementIds] = useState([])
  const [sectorId, setSectorId] = useState('')
  const [subsectorIds, setSubsectorIds] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [territoryId, setTerritoryId] = useState('')
  const [zoneId, setZoneId] = useState('')
  const [siteAddress, setSiteAddress] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [actualStartDate, setActualStartDate] = useState(null)
  const [actualEndDate, setActualEndDate] = useState(null)
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [partner, setPartner] = useState('')
  const [visitPurpose, setVisitPurpose] = useState('')
  const [attempt, setAttempt] = useState('')
  const [requestStatus, setRequestStatus] = useState('created')
  const [monitoringCycleId, setMonitoringCycleId] = useState('')
  const [requestJustification, setRequestJustification] = useState('')
  const [requestJustificationDetails, setRequestJustificationDetails] = useState('')
  const [flashReportReady, setFlashReportReady] = useState(false)
  const [flashReportStatus, setFlashReportStatus] = useState('')
  const [ipFlashComments, setIpFlashComments] = useState('')
  const [ipFlashCommentsUpdated, setIpFlashCommentsUpdated] = useState(null)
  const [ipFlashCommentsUpdater, setIpFlashCommentsUpdater] = useState('')
  const [bhaFlashComments, setBhaFlashComments] = useState('')
  const [bhaFlashCommentsUpdated, setBhaFlashCommentsUpdated] = useState(null)
  const [bhaFlashCommentsUpdater, setBhaFlashCommentsUpdater] = useState('')
  const [smlp2FlashComments, setSmlp2FlashComments] = useState('')
  const [smlp2FlashCommentsUpdated, setSmlp2FlashCommentsUpdated] = useState(null)
  const [smlp2FlashCommentsUpdater, setSmlp2FlashCommentsUpdater] = useState('')
  const [issueClosed, setIssueClosed] = useState(false)

  const [resourcesCount, setResourcesCount] = useState(0)
  const [alertMessage, setAlertMessage] = useState('')
  const [updated, setUpdated] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [showAddZoneModal, setShowAddZoneModal] = useState(false)
  const [tabKey, setTabKey] = useState(props.tabKey || 'sitevisit')
  const [startFocused, setStartFocused] = useState(false)
  const [endFocused, setEndFocused] = useState(false)
  const [actualStartFocused, setActualStartFocused] = useState(false)
  const [actualEndFocused, setActualEndFocused] = useState(false)
  const [showAddResourceModal, setShowAddResourceModal] = useState(false)

  useEffect(() => {
    if (tabKey === "fulcrum" && Object.keys(fulcrumData).length === 0) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.FORMS_FOR_VISIT_URL}/${props.id}`)
        setFulcrumData(response.data || {})
      }
      fetchData()
    }
  }, [tabKey])

  useEffect(() => {
    if (updated) {
      props.refreshData()
      setUpdated(false)
    }
    if (deleted) {
      props.refreshData()
      setDeleted(false)
      props.closeModal()
    }
  }, [updated, deleted])


  useEffect(() => {
    if (props.id && !resourcesCount && !showAddResourceModal) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}/${props.id}.json`)
        setActivityId(response.data.activity_id || '')
        setOfficeId(response.data.office_id || '')
        setIpPlanId(response.data.ip_plan_id || '')
        setAgreementIds(response.data.agreements ? response.data.agreements.map(a => a.id) : [])
        setSectorId(response.data.sector_id || '')
        setSubsectorIds(response.data.subsectors ? response.data.subsectors.map(s => s.id) : [])
        setDistrictId(response.data.district ? response.data.district.id : '')
        setTerritoryId(response.data.territory ? response.data.territory.id : '')
        setZoneId(response.data.zone_id || '')
        setSiteAddress(response.data.site_address || '')
        setStartDate(response.data.start_date ? moment(response.data.start_date) : null)
        setEndDate(response.data.end_date ? moment(response.data.end_date) : null)
        setActualStartDate(response.data.actual_start_date ? moment(response.data.actual_start_date) : null)
        setActualEndDate(response.data.actual_end_date ? moment(response.data.actual_end_date) : null)
        setLatitude(response.data.latitude || 0)
        setLongitude(response.data.longitude || 0)
        setPartner(response.data.partner || '')
        setVisitPurpose(response.data.visit_purpose || 'tpm')
        setAttempt(response.data.attempt || '')
        setRequestStatus(response.data.request_status || 'created')
        setMonitoringCycleId(response.data.monitoring_cycle_id || '')
        setRequestJustification(response.data.request_justification || '')
        setRequestJustificationDetails(response.data.data.request_justification_details || '')
        setFlashReportReady(response.data.flash_report_ready || false)
        setFlashReportStatus(response.data.flash_report_status || null)
        setIpFlashComments(response.data.ip_flash_comments || '')
        setIpFlashCommentsUpdated(response.data.ip_flash_comments_updated || null)
        setIpFlashCommentsUpdater(response.data.get_ip_flash_comments_updater || '')
        setBhaFlashComments(response.data.bha_flash_comments || '')
        setBhaFlashCommentsUpdated(response.data.bha_flash_comments_updated || null)
        setBhaFlashCommentsUpdater(response.data.get_bha_flash_comments_updater || '')
        setSmlp2FlashComments(response.data.smlp2_flash_comments || '')
        setSmlp2FlashCommentsUpdated(response.data.smlp2_flash_comments_updated || null)
        setSmlp2FlashCommentsUpdater(response.data.get_smlp2_flash_comments_updater || '')
        setResourcesCount(response.data.get_resources_count)
        setIssueClosed(response.data.fr_issue_closed || false)
      }

      fetchData()
    }
  }, [props.id, showAddResourceModal])


  const createFormData = function()  {
    let formData = new FormData()
    formData.append('monitoring_request[activity_id]', activityId || "")
    formData.append('monitoring_request[ip_plan_id]', ipPlanId || "")
    agreementIds.forEach(a => { formData.append('monitoring_request[agreement_ids][]', a) })
    formData.append('monitoring_request[sector_id]', sectorId || "")
    subsectorIds.forEach(s => { formData.append('monitoring_request[subsector_ids][]', s) })
    formData.append('monitoring_request[district_id]', districtId || "")
    formData.append('monitoring_request[territory_id]', territoryId || "")
    formData.append('monitoring_request[zone_id]', zoneId || "")
    formData.append('monitoring_request[site_address]', siteAddress || "")
    formData.append('monitoring_request[start_date]', startDate) 
    formData.append('monitoring_request[end_date]', endDate)
    formData.append('monitoring_request[actual_start_date]', actualStartDate)
    formData.append('monitoring_request[actual_end_date]', actualEndDate)
    formData.append('monitoring_request[latitude]', latitude || 0)
    formData.append('monitoring_request[longitude]', longitude || 0)
    formData.append('monitoring_request[partner]', partner || "")
    formData.append('monitoring_request[visit_purpose]', visitPurpose || "")
    formData.append('monitoring_request[attempt]', attempt || "")
    formData.append('monitoring_request[request_status]', requestStatus)
    formData.append('monitoring_request[monitoring_cycle_id]', monitoringCycleId || "")
    formData.append('monitoring_request[request_justification]', requestJustification)
    formData.append('monitoring_request[request_justification_details]', requestJustificationDetails)
    formData.append('monitoring_request[flash_report_ready]', flashReportReady || false)
    formData.append('monitoring_request[flash_report_status]', flashReportStatus)
    formData.append('monitoring_request[ip_flash_comments]', ipFlashComments || "")
    formData.append('monitoring_request[bha_flash_comments]', bhaFlashComments || "")
    formData.append('monitoring_request[smlp2_flash_comments]', smlp2FlashComments || "")
    formData.append('monitoring_request[fr_issue_closed]', issueClosed || false)

    return formData
  }

  const handleSubmit = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()
    const successNotice = id ? I18n.t("notices.update_success") : I18n.t("notices.create_success")
    const res = id ? await axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, data ) : await axios.post(appConstants.MONITORING_EVENT_URL, data )
    const notice = res.status === 200 ? successNotice : I18n.t("notices.failure")
    setIpFlashCommentsUpdated(res.data.ip_flash_comments_updated)
    setIpFlashCommentsUpdater(res.data.get_ip_flash_comments_updater)
    setBhaFlashCommentsUpdated(res.data.bha_flash_comments_updated)
    setBhaFlashCommentsUpdater(res.data.get_bha_flash_comments_updater)
    setSmlp2FlashCommentsUpdated(res.data.smlp2_flash_comments_updated)
    setSmlp2FlashCommentsUpdater(res.data.get_smlp2_flash_comments_updater)
    setAlertMessage(notice)
    setUpdated(true)
  }

  const renderAlertMessage = () => {
    return alertMessage ? (
      <Alert variant={'info'} onClose={() => setAlertMessage("")} dismissible>
        {alertMessage}
      </Alert>
    ) : ""
  }

  const renderAddZoneButton = () => {
    return territoryId && props.allowed.edit_flash_report ? (
      <Button variant="outline-secondary" onClick={() => setShowAddZoneModal(true)} className="add-collection-btn" title={I18n.t('zone.add')}><BiPlus /></Button>
    ) : ""
  }

  const closeAddZoneModal = (z) => {
    setShowAddZoneModal(false)
    setZoneId(z)
    setAlertMessage("")
  }

  const renderCancelButton = () => {
    return (<Button variant="outline-secondary" style={{float: 'right'}} onClick={() => props.closeModal()}>{I18n.t("navigation.cancel")}</Button>)
  }

  return showAddZoneModal ? (
    <ZoneForm
      authenticityToken={props.authenticityToken}
      zone={{territory_id: territoryId}}
      isModal={true}
      closeModal={closeAddZoneModal} />
  ) : (
    <Form>
      <Modal
        show={showAddResourceModal}
        onHide={()=>setShowAddResourceModal(false)}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('resource.add_a_resource')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResourceForm
            authenticityToken={props.authenticityToken}
            isModal={true}
            closeModal={()=>setShowAddResourceModal(false)}
            resource={{monitoring_request_id: parseInt(id), activities: [{id: activityId}], ip_plans: [{id: ipPlanId}], office_id: officeId, monitoring_cycle_id: monitoringCycleId, tags: [], collections: []}}
          />
        </Modal.Body>
      </Modal>
      <Row>
        <Col>
          {renderAlertMessage()}
        </Col>
      </Row>
      <Tabs activeKey={tabKey} id="sitevisit-tabs" onSelect={(k) => setTabKey(k)}>
        <Tab eventKey="sitevisit" title={I18n.t('navigation.site_visit_tab')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={4} controlid="requestForm.activity">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.activity')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report}
                selected={activityId}
                onValueChange={(e) => setActivityId(e ? e.value : "")}
                url={appConstants.ACTIVITIES_URL + ".json"}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.ip_plan">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_plan')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report}
                selected={ipPlanId}
                onValueChange={(e) => setIpPlanId(e ? e.value : "")}
                url={appConstants.IP_PLANS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.agreements">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.agreements')}</Form.Label>
              <SelectMultiple
                isDisabled={!props.allowed.edit_flash_report}
                selected={agreementIds}
                onChange={(e) => setAgreementIds(e ? e.map(i => i.value) : [])}
                url={appConstants.AGREEMENTS_URL + ".json"}
                isClearable={true}
                placeholder={I18n.t('selects.multiple_optional')} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.sector">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sector')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report}
                selected={sectorId}
                onValueChange={(e) => setSectorId(e ? e.value : "")}
                url={appConstants.SECTORS_URL}
                placeholder={I18n.t('selects.single')}
                isClearable={true} />
            </Form.Group>

            <Form.Group as={Col} md={8} className="mb-3" controlid="requestForm.subsector">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.subsectors')}</Form.Label>
              <SelectMultiple
                isDisabled={!props.allowed.edit_flash_report}
                selected={subsectorIds}
                onChange={(e) => setSubsectorIds(e ? e.map(i => i.value) : [])}
                url={appConstants.SUBSECTORS_URL + "?sector_id=" + (sectorId || -1)}
                placeholder={I18n.t('selects.multiple_optional')}
                groupedName='subsectors'
                isDependent={true} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.district">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.district')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report}
                selected={districtId}
                onValueChange={(e) => {
                  setDistrictId(e ? e.value : "")
                  setTerritoryId("")
                  setZoneId("")
                }}
                url={appConstants.DISTRICTS_URL}
                placeholder={I18n.t('selects.single')}
                isClearable={true} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.territory">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.territory')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report}
                selected={territoryId}
                onValueChange={(e) => {
                  setTerritoryId(e ? e.value : "")
                  setZoneId("")
                }}
                url={appConstants.TERRITORIES_BY_DISTRICT_URL + (districtId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.zone">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.zone')}</Form.Label> &nbsp;
              {renderAddZoneButton()}
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report}
                selected={zoneId}
                onValueChange={(e) => setZoneId(e ? e.value : "")}
                url={appConstants.ZONES_BY_TERRITORY_URL + (territoryId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>
          </Row>
          
          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.site_address">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.site_address')}</Form.Label>
                <Form.Control 
                  disabled={!props.allowed.edit_flash_report}
                  name="siteAddress"
                  type="text"
                  value={siteAddress}
                  onChange={(e) => setSiteAddress(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.start_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.start_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_flash_report}
                date={startDate}// momentPropTypes.momentObj or null
                onDateChange={start_date => setStartDate( start_date )} // PropTypes.func.isRequired
                focused={startFocused} // PropTypes.bool
                onFocusChange={({ focused: start_focused }) => setStartFocused( start_focused )} // PropTypes.func.isRequired
                id="start_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.end_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.end_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_flash_report}
                date={endDate}// momentPropTypes.momentObj or null
                onDateChange={end_date => setEndDate( end_date )} // PropTypes.func.isRequired
                focused={endFocused} // PropTypes.bool
                onFocusChange={({ focused: end_focused }) => setEndFocused( end_focused )} // PropTypes.func.isRequired
                id="end_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.actual_start_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.actual_start_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_flash_report}
                date={actualStartDate}// momentPropTypes.momentObj or null
                onDateChange={actual_start_date => setActualStartDate( actual_start_date )} // PropTypes.func.isRequired
                focused={actualStartFocused} // PropTypes.bool
                onFocusChange={({ focused: actual_start_focused }) => setActualStartFocused( actual_start_focused )} // PropTypes.func.isRequired
                id="actual_start_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.actual_end_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.actual_end_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_flash_report}
                date={actualEndDate}// momentPropTypes.momentObj or null
                onDateChange={actual_end_date => setActualEndDate( actual_end_date )} // PropTypes.func.isRequired
                focused={actualEndFocused} // PropTypes.bool
                onFocusChange={({ focused: actual_end_focused }) => setActualEndFocused( actual_end_focused )} // PropTypes.func.isRequired
                id="actual_end_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.latitude">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.latitude')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_flash_report}
                name="latitude"
                type="text"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.longitude">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.longitude')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_flash_report}
                name="longitude"
                type="text"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.partner">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.partner')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_flash_report}
                name="partner"
                type="text"
                value={partner}
                onChange={(e) => setPartner(e.target.value)} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.visit_purpose">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_purpose')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report_meta}
                selected={visitPurpose}
                onValueChange={(e) => setVisitPurpose(e ? e.value : "")}
                url={appConstants.VISIT_PURPOSES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.attempt">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.attempt')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report_meta}
                selected={attempt}
                onValueChange={(e) => setAttempt(e ? e.value : "")}
                url={appConstants.ATTEMPTS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} controlId="requestForm.request_status">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_status')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report_meta}
                selected={requestStatus}
                onValueChange={(e) => setRequestStatus(e.value)}
                url={appConstants.EVENT_STATUSES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.monitoring_cycle">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitoring_cycle')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report_meta}
                selected={monitoringCycleId}
                onValueChange={(e) => setMonitoringCycleId(e ? e.value : "")}
                url={appConstants.MONITORING_CYCLES_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
          </Row>
          { requestStatus == 'pending' ?
          (
          <Row>
            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.postponed_reason">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_flash_report}
                selected={requestJustification}
                onValueChange={(e) => setRequestJustification(e ? e.value : "")}
                url={appConstants.JUSTIFICATIONS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.postponed_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification_details')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_flash_report}
                name="requestJustificationDetails"
                type="text"
                value={requestJustificationDetails}
                onChange={(e) => setRequestJustificationDetails(e.target.value)} />
            </Form.Group>
          </Row> ) : ""
          }

          <Form.Group  className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.edit_flash_report} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            { renderCancelButton() }
          </Form.Group>
        </Tab>
    
        <Tab eventKey="ip_feedback" title={I18n.t('navigation.comments_and_feedback_tab')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={8} className="mb-3" controlid="requestForm.ip_flash_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_flash_comments')} &nbsp; {ipFlashCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(ipFlashCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {ipFlashCommentsUpdater}</span> : ""} </Form.Label><br/>
              <Form.Control
                disabled={!props.allowed.ip_flash_comments}
                as="textarea"
                name="ip_flash_comments"
                value={ipFlashComments}
                onChange={(e) => setIpFlashComments(e.target.value)}
                rows="4" />
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3 mt-2" controlid="requestForm.report_upload_button">
              <Button variant="outline-secondary" size="md" className="mt-4 ms-3" onClick={()=>setShowAddResourceModal(true)}>{I18n.t('activerecord.attributes.monitoring_request.upload_resource')}</Button>
            </Form.Group>
          </Row>
          <Row className="mt-3">
            <Form.Group as={Col} md={8} className="mb-3" controlid="requestForm.bha_flash_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.bha_flash_comments')} &nbsp; {bhaFlashCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(bhaFlashCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {bhaFlashCommentsUpdater}</span> : ""} </Form.Label><br/>
              <Form.Control
                disabled={!props.allowed.bha_flash_comments}
                as="textarea"
                name="bha_flash_comments"
                value={bhaFlashComments}
                onChange={(e) => setBhaFlashComments(e.target.value)}
                rows="4" />
            </Form.Group>
          </Row>
          <Row className="mt-3">
            <Form.Group as={Col} md={8} className="mb-3" controlid="requestForm.smlp2_flash_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_flash_comments')} &nbsp; {smlp2FlashCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(smlp2FlashCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {smlp2FlashCommentsUpdater}</span> : ""} </Form.Label><br/>
              <Form.Control
                disabled={!props.allowed.smlp2_flash_comments}
                as="textarea"
                name="smlp2_flash_comments"
                value={smlp2FlashComments}
                onChange={(e) => setSmlp2FlashComments(e.target.value)}
                rows="4" />
            </Form.Group>
          </Row>
          <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.fr_issue_closed">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.fr_issue_closed')}</Form.Label>
              <Form.Check
                disabled={!props.allowed.close_flash_report}
                type="switch"
                name="issue_closed"
                checked={issueClosed}
                value={true}
                onChange={() => setIssueClosed(!issueClosed)}
                bsPrefix="issueClosed-switch" />
            </Form.Group>
          <Form.Group className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.submit_flash_report} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          </Form.Group>
        </Tab>

        { resourcesCount ?
          (
            <Tab eventKey="resources" title={I18n.t('navigation.resources_tab')} className="mb-3">
              <div className="mt-3">
                <VisitResourceTable siteVisitId={props.id} refresh={showAddResourceModal} canDownload={true} />
              </div>
            </Tab>
          ) : ""
        }
      </Tabs>
    </Form>
  )
}