import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  border: ${props =>
    props.missingIpInfo && !props.isFlashBoard
    ? '3px solid darkred;'
    : props.missingIpComments && !props.isFlashBoard
    ? '3px solid goldenrod;'
    : props.missingIpFlashComments && props.isFlashBoard
    ? '3px solid darkorange;'
    : '1px solid lightgrey;'}
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.1s ease;
  background-color: ${props => 
    props.hold
      ?'mistyrose'
      : props.isDragging 
      ? 'aliceblue' 
      : 'white'}
`;

export default function Task(props){

    const isDragDisabled = false
    const taskContent = props.taskContent
    return (
      <Draggable 
        draggableId={props.task.id} 
        index={props.index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
            hold={props.task.hold}
            missingIpInfo={props.task.missing_ip_info}
            missingIpComments={props.task.missing_ip_comments}
            missingIpFlashComments={props.task.missing_ip_flash_comments}
            isFlashBoard={props.isFlashBoard}
            status={props.task.request_status_id}
          >
            {taskContent(props.task)}

          </Container>
        )}
      </Draggable>
    )
}