import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import I18n from 'i18n-js/locales.js'
import SelectSingle from '../selects/SelectSingle.js'
import { appConstants } from 'utils/constants.js'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function MonitoringRequestFilters(props) {

  const renderActivity = () => {
    return (
      "activity_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="activity">
            <SelectSingle
              selected={parseInt(props.includedFilters["activity_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "activity_id")}
              url={appConstants.ACTIVITIES_URL + '.json'}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.activity')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderIP = () => {
    return (
      "implementing_partner_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="implementing_partner">
            <SelectSingle
              selected={parseInt(props.includedFilters["implementing_partner_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "implementing_partner_id")}
              url={appConstants.IPS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.ip')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderGovernorate = () => {
    return (
      "governorate_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="governorate">
            <SelectSingle
              selected={parseInt(props.includedFilters["governorate_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "governorate_id")}
              url={appConstants.GOVERNORATES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.governorate')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderDistrict = () => {
    return (
      "district_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="district">
            <SelectSingle
              selected={parseInt(props.includedFilters["district_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "district_id")}
              url={appConstants.DISTRICTS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.district')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderTerritory = () => {
    return (
      "territory_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="territory">
            <SelectSingle
              selected={parseInt(props.includedFilters["territory_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "territory_id")}
              url={appConstants.TERRITORIES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.territory')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderZone = () => {
    return (
      "zone_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="zone">
            <SelectSingle
              selected={parseInt(props.includedFilters["zone_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "zone_id")}
              url={appConstants.ZONES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.zone')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderOffice = () => {
    return (
      "office_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="office">
            <SelectSingle
              selected={parseInt(props.includedFilters["office_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "office_id")}
              url={appConstants.OFFICES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.office')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderMonitor = () => {
    return (
      "user_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="user">
            <SelectSingle
              selected={parseInt(props.includedFilters["user_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "user_id")}
              url={appConstants.MONITORS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.user')} 
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderReviewer = () => {
    return (
      "reviewer_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="reviewer">
            <SelectSingle
              selected={parseInt(props.includedFilters["reviewer_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "reviewer_id")}
              url={appConstants.REVIEWERS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.reviewer')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderAssignee = () => {
    return (
      "assignee_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="assignee">
            <SelectSingle
              selected={parseInt(props.includedFilters["assignee_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "assignee_id")}
              url={appConstants.ADMINS_URL}
              placeholder={I18n.t('activerecord.attributes.ip_plan.user')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
      </Col>
      : ""
    )
  }

  const renderStartWeek = () => {
    return (
      "start_week" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="start_week">
            <SelectSingle
              selected={props.includedFilters["start_week"]}
              onValueChange={(e) => props.handleFilterChange(e, "start_week")}
              url={appConstants.TIME_INTERVALS_URL}
              placeholder={I18n.t('selects.week')}
              isClearable={true}
              isFilter={true}  />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderTimeInterval = () => {
    return (
      "time_interval_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="time_interval">
            <SelectSingle
              selected={props.includedFilters["time_interval_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "time_interval_id")}
              url={appConstants.TIME_INTERVALS_URL}
              placeholder={I18n.t('selects.time_period')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderTimeSlider = () => {
    const createSliderWithTooltip = Slider.createSliderWithTooltip;
    const Range = createSliderWithTooltip(Slider.Range);
    return (
      "days_range" in props.includedFilters
      ? <Col>
        <Form.Group controlId="days_back">
          <p>{I18n.t('selects.days_back')}</p>
          <Range
            defaultValue={props.includedFilters["days_range"] || [0,30]}
            onAfterChange={(e) => props.handleFilterChange({value: e, label: e}, "days_range")}
            reverse={true}
            max={90}
            trackStyle={[{backgroundColor: '#4772A5'}]}
            handleStyle={[{
              borderColor: '#4772A5',
              borderWidth: '3px',
              backgroundColor: '#FFFFFF',
            },{
              borderColor: '#4772A5',
              borderWidth: '3px',
              backgroundColor: '#FFFFFF',
            }]} />
        </Form.Group>
      </Col>
      : ""
    )
  }

  const renderTotalScore = () => {
    return (
      "total_score" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="total_score">
            <SelectSingle
              selected={props.includedFilters["total_score"]}
              onValueChange={(e) => props.handleFilterChange(e, "total_score")}
              url={appConstants.TOTAL_SCORES_URL}
              placeholder={I18n.t('selects.total_score')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderStatus = () => {
    return (
      "request_status_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="request_status">
            <SelectSingle
              selected={props.includedFilters["request_status_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "request_status_id")}
              url={appConstants.EVENT_STATUSES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.top_level_status')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderFlashReportStatus = () => {
    return (
      "flash_report_status" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="flash_report_status">
            <SelectSingle
              selected={props.includedFilters["flash_report_status"]}
              onValueChange={(e) => props.handleFilterChange(e, "flash_report_status")}
              url={appConstants.FLASH_REPORT_STATUSES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.top_level_flash_report_status')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderMode = () => {
    return (
      "virtual" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="virtual">
            <SelectSingle
              selected={props.includedFilters["virtual"]}
              onValueChange={(e) => props.handleFilterChange(e, "virtual")}
              url={appConstants.EVENT_MODES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.mode')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderMonitoringCycle = () => {
    return (
      "monitoring_cycle_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="monitoring_cycle">
            <SelectSingle
              selected={parseInt(props.includedFilters["monitoring_cycle_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "monitoring_cycle_id")}
              url={appConstants.MONITORING_CYCLES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.monitoring_cycle')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderSector = () => {
    return (
      "sector_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="sector">
            <SelectSingle
              selected={props.includedFilters["sector_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "sector_id")}
              url={appConstants.SECTORS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.sector')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderResourceType = () => {
    return (
      "resource_type_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="sector">
            <SelectSingle
              selected={props.includedFilters["resource_type_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "resource_type_id")}
              url={appConstants.RESOURCE_TYPE_TAGS_URL}
              placeholder={I18n.t('activerecord.attributes.tag_type.resource_type')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderSiteVisit = () => {
    return (
      "monitoring_request_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="site_visit">
            <SelectSingle
              selected={parseInt(props.includedFilters["monitoring_request_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "monitoring_request_id")}
              url={appConstants.FLAGGABLE_REQUESTS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.monitoring_event')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderSearch = () => {
    return (
      !props.excludeSearch
      ?   <Row  className="p-2">
            <Col>
              <Form.Control type="text" placeholder={I18n.t("headers.monitoring_request.search")} onChange={(e) => props.handleSearch(e)} />
            </Col>
          </Row>
      : ""
    )
  }

  const renderLearningModuleStatus = () => {
    return (
      "learning_module_status" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="learning_module_status">
            <SelectSingle
              selected={props.includedFilters["learning_module_status"]}
              onValueChange={(e) => props.handleFilterChange(e, "learning_module_status")}
              url={appConstants.LEARNING_MODULE_STATUSES_URL}
              placeholder={I18n.t('activerecord.attributes.flag.learning_module_status')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderLessonTheme = () => {
    return (
      "lesson_theme" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="lesson_theme">
            <SelectSingle
              selected={props.includedFilters["lesson_theme"]}
              onValueChange={(e) => props.handleFilterChange(e, "lesson_theme")}
              url={appConstants.LESSON_THEMES_URL}
              placeholder={I18n.t('activerecord.attributes.flag.theme')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderQuarter = () => {
    return (
      "quarter_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="quarter">
            <SelectSingle
              selected={props.includedFilters["quarter_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "quarter_id")}
              url={appConstants.QUARTERS_URL + '.json'}
              placeholder={I18n.t('activerecord.attributes.flag.quarter')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderSemester = () => {
    return (
      "semester_id" in props.includedFilters
      ?  <Col>
          <Form.Group controlId="semester">
            <SelectSingle
              selected={props.includedFilters["semester_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "semester_id")}
              url={appConstants.SEMESTERS_URL + '.json'}
              placeholder={I18n.t('activerecord.attributes.flag.semester')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  return (
      <Form  className="border rounded bg-light mb-2">
        <Row  className="p-2">
          {renderSiteVisit()}
          {renderResourceType()}
          {renderMonitoringCycle()}
          {renderIP()}
          {renderActivity()}
          {renderSector()}
          {renderGovernorate()}
          {renderDistrict()}
          {renderTerritory()}
          {renderZone()}
          {renderOffice()}
          {renderMonitor()}
          {renderReviewer()}
          {renderAssignee()}
          {renderStartWeek()}
          {renderTimeInterval()}
          {renderTotalScore()}
          {renderTimeSlider()}
          {renderStatus()}
          {renderFlashReportStatus()}
          {renderMode()}
          {renderLearningModuleStatus()}
          {renderLessonTheme()}
          {renderQuarter()}
          {renderSemester()}
        </Row>
        {renderSearch()}
      </Form>
  )
}
